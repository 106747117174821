import React from "react";
import ReactDOM from "react-dom";
import "./styles/globals.scss";
import reportWebVitals from "./reportWebVitals";
import axios from "axios";
import createStore from "./cores/createStore";
import { Provider } from "react-redux";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import PriceResult from "./pages/PriceResult";
import "intersection-observer";
import Home from "./pages/Home";
import Inspector from "./pages/Inspector/Inspector";
import SamplePriceResult from "./pages/SamplePriceResult";
import SamplePage from "./pages/SamplePage";
import { EmbedRouter } from "./pages/embed";

axios.defaults.baseURL = process.env.REACT_APP_API;
const store = createStore();
ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <Router>
        <Switch>
          <Route path="/" exact component={Home} />
          <Route path="/embed" component={EmbedRouter} />
          <Route path="/price_result/:hashId">
            <PriceResult />
          </Route>
          <Route path="/sample" component={SamplePage} />
          <Route path="/sample-price-result">
            <SamplePriceResult />
          </Route>
          <Route path="/inspectors/:hashId">
            <Inspector />
          </Route>
        </Switch>
      </Router>
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
