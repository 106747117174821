import { Button, colors, Typography } from "@PRNDcompany/heydealer-ui";
import { FC } from "react";
import { useLocation } from "react-router-dom";

const CURRENT_STEP_ARRAY = ["car_number", "owner_name"];

const onCTAClick = () => {
  window.Android.close();
  window.webkit.messageHandlers.dismiss.postMessage({});
};

const CTA: FC = () => {
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);

  const current_step = searchParams.get("current_step");
  if (!current_step || !CURRENT_STEP_ARRAY.includes(current_step)) return null;

  return (
    <div
      style={{
        display: "grid",
        gap: "1rem",
        padding: "1rem",
        width: "100%",
        boxSizing: "border-box",
        background: colors.base_white,
        position: "sticky",
        bottom: 0,
        isolation: "isolate",
        zIndex: 1,
        borderTopLeftRadius: "1rem",
        borderTopRightRadius: "1rem",
      }}
    >
      <div
        style={{
          padding: "0.5rem",
          height: "2.75rem",
          boxSizing: "border-box",
          display: "flex",
          alignItems: "center",
        }}
      >
        <Typography variant="body_2">🔒 입력하신 정보는 동의 없이 공개되지 않아요.</Typography>
      </div>
      <Button variant="primary" fullWidth onClick={onCTAClick}>
        {current_step === "car_number" ? "내차 번호판 입력하기" : "소유자명 입력하기"}
      </Button>
    </div>
  );
};

export default CTA;
