import React, { useState } from "react";
import styles from "./SimilarCar.module.scss";
import { SimilarCar as ISimilarCar } from "../../../declaration/cars";
import { get } from "lodash";
import Typography from "../../Typograph/Typography";
import { toLocaleString } from "../../../cores/toLocaleString";
import classNames from "classnames";
import LightInk from "../../LightInk/LightInk";
import ImageLoader from "../../ImageLoader/ImageLoader";

interface Props {
  similarCar: ISimilarCar;
  isLastCar?: boolean;
  scrollToBottom?: () => void;
}

const SimilarCar: React.FC<Props> = ({
  similarCar,
  isLastCar,
  scrollToBottom,
}) => {
  const [isOpen, setOpen] = useState(false);
  const detail = get(similarCar, "detail");
  const mainImageUrl = get(detail, "main_image_url");
  const mileageDisplay = get(detail, "mileage_display");
  const year = get(detail, "year");
  const gradePartName = get(detail, "grade_part_name");
  const accidentSummaryDisplay = get(detail, "accident_summary_display");
  const conditionDisplay = get(detail, "condition_display");
  const optionDisplay = get(detail, "option_display");

  const auction = get(similarCar, "auction");
  const highestBidPrice = get(auction, "highest_bid_price");
  const approvedAtDisplay = get(auction, "approved_at_display");
  const bidsCount = get(auction, "bids_count");

  const onClickSummary = () => {
    setOpen((prev) => !prev);
    if (isLastCar && scrollToBottom) {
      scrollToBottom();
    }
  };
  return (
    <div className={classNames(styles.similarCar, { [styles.open]: isOpen })}>
      <div className={styles.summary} onClick={onClickSummary}>
        <ImageLoader
          image={mainImageUrl}
          className={styles.mainImageWrapper}
          imageClassName={styles.mainImage}
        />
        <div className={styles.content}>
          <div className={styles.section1}>
            <span className={styles.yearWrapper}>
              <Typography variant="h3">{year}년형</Typography>
              <span className={styles.border} />
              <Typography variant="h3">{mileageDisplay}</Typography>
            </span>
            <span className={styles.highestBidPrice}>
              {toLocaleString(highestBidPrice)}만원
            </span>
          </div>
          <div className={styles.section2}>
            <Typography variant="body2">{gradePartName}</Typography>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              viewBox="0 0 16 16"
              className={classNames(styles.arrow, {
                [styles.openArrow]: isOpen,
              })}
            >
              <g fill="none" fillRule="evenodd" opacity=".64">
                <g fill="#AFC2DB" fillRule="nonzero">
                  <g>
                    <g>
                      <path
                        d="M12.146 5.646L12.854 6.354 8 11.207 3.146 6.354 3.854 5.646 8 9.793z"
                        transform="translate(-304 -896) translate(24 852) translate(280 44)"
                      />
                    </g>
                  </g>
                </g>
              </g>
            </svg>
          </div>
        </div>
        <LightInk />
      </div>

      <div className={styles.detail}>
        <div className={styles.fieldSet}>
          <span className={styles.label}>사고</span>
          <span className={styles.value}>{accidentSummaryDisplay}</span>
        </div>
        <div className={styles.fieldSet}>
          <span className={styles.label}>상태</span>
          <span className={styles.value}>{conditionDisplay}</span>
        </div>
        <div className={styles.fieldSet}>
          <span className={styles.label}>옵션</span>
          <span
            className={styles.options}
            dangerouslySetInnerHTML={{ __html: optionDisplay }}
          />
        </div>
        <div className={styles.footer}>
          {approvedAtDisplay} · 딜러입찰 {bidsCount}명
        </div>
      </div>
    </div>
  );
};

export default SimilarCar;
