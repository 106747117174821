import { DisplayObject } from "canvas-object";
import { get } from 'lodash';

interface GraphicsOptions {
  context: CanvasRenderingContext2D;
}

export class Graphics {
  context: CanvasRenderingContext2D;
  displayObjects: Array<DisplayObject> = [];

  constructor({ context }: GraphicsOptions) {
    this.context = context;
  }

  isEndAnimation() {
    let isEnd = true;

    for (let i = 0, length = this.displayObjects.length; i < length; i++) {
      const isEndAnimation = get(this.displayObjects[i], 'isEndAnimation');
      if (typeof isEndAnimation === 'boolean') {
        if (!isEndAnimation) {
          isEnd = false;
          break;
        }
      }
    }

    return isEnd;
  }

  add(...child: Array<DisplayObject>) {
    this.displayObjects.push(...child);
  }

  unshift(...child: Array<DisplayObject>) {
    this.displayObjects.unshift(...child);
  }

  render() {
    for (let i = 0, length = this.displayObjects.length; i < length; i++) {
      this.displayObjects[i].render(this.context);
    }
  }

  update(delta: number) {
    for (let i = 0, length = this.displayObjects.length; i < length; i++) {
      this.displayObjects[i].update(delta);
    }
  }
}
