import React, { useEffect, useRef, useState } from "react";
import styles from "./Review.module.scss";
import StarRating from "react-svg-star-rating";
import { Review as IReview } from "../../../declaration/cars";
import { get, map } from "lodash";
import getThumbnailUrl from "../../../cores/getThumbnailUrl";
import Typography from "../../Typograph/Typography";
import { toLocaleString } from "../../../cores/toLocaleString";
import Reduction from "./Reduction/Reduction";
import classNames from "classnames";
import LightInk from "../../LightInk/LightInk";

interface Props {
  review: IReview;
}

const Review: React.FC<Props> = ({ review }) => {
  const rating = get(review, "rating");
  const content = get(review, "content");
  const imageUrls = get(review, "image_urls");
  const selectedBidPrice = get(review, [
    "car",
    "auction",
    "selected_bid",
    "price",
  ]);
  const trade = get(review, ["car", "trade"]);
  const tradedPrice = get(trade, "traded_price");
  const reductionsForReview = get(trade, "reductions_for_review");
  const hasReductions = reductionsForReview?.length !== 0;

  const [contentLineCount, setContentLineCount] = useState(1);
  const dummyContentRef = useRef<HTMLDivElement>(null);
  const [isOpen, setOpen] = useState(false);

  const onClickShowMore = () => {
    setOpen(true);
  };

  useEffect(() => {
    if (dummyContentRef.current) {
      setContentLineCount(
        dummyContentRef.current.getBoundingClientRect().height / 23
      );
    }
  }, []);

  return (
    <div className={styles.review}>
      <StarRating
        isReadOnly={true}
        size={14}
        isHalfRating={true}
        initialRating={rating / 2}
        activeColor="#396eff"
        roundedCorner={false}
        starClassName={styles.star}
        emptyColor="#E9EDF4"
      />
      <div className={styles.contentWrapper}>
        <div
          className={classNames(styles.content, styles.dummyContent)}
          ref={dummyContentRef}
        >
          {content}
        </div>
      </div>
      <div className={styles.contentWrapper}>
        <div
          className={classNames(styles.content, { [styles.isOpen]: isOpen })}
        >
          {content}
        </div>
        {contentLineCount >= 4 && !isOpen && (
          <div className={styles.showMore} onClick={onClickShowMore}>
            더보기
            <LightInk />
          </div>
        )}
      </div>
      <div className={styles.carImageWrapper}>
        {map(imageUrls, (imageUrl, index) => (
          <img
            key={index}
            src={`${getThumbnailUrl(100)}/${imageUrl}`}
            alt="차량"
            className={styles.carImage}
          />
        ))}
      </div>
      <div className={styles.trade}>
        <div className={styles.fieldSet}>
          <div className={styles.label}>입찰가</div>
          <div className={styles.value}>
            {toLocaleString(selectedBidPrice)}만원
          </div>
        </div>
        <div className={styles.fieldSet}>
          <div className={styles.label}>매입가</div>
          <div className={styles.value}>{toLocaleString(tradedPrice)}만원</div>
        </div>
        {hasReductions && <Reduction trade={trade} />}
        <div className={styles.border} />
        <div className={styles.footer}>
          {hasReductions ? (
            <div className={styles.hasReduction}>
              <span className={styles.from}>헤이딜러 감가심사센터</span>
              <span className={styles.mark}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  className={styles.icon}
                >
                  <g fill="none" fillRule="evenodd">
                    <g fill="#396EFF">
                      <g>
                        <g>
                          <g>
                            <g>
                              <g>
                                <path
                                  d="M7.993 1.335c.086-.006.178.008.272.046l5.447 2.251c.169.07.268.273.268.435 0 1.22.05 2.449-.007 3.668-.103 2.224-1.283 4.37-3.178 5.732-.8.575-2.187 1.43-3.237 1.142-1.098-.302-2.1-.862-2.935-1.585-1.67-1.444-2.602-3.517-2.62-5.627-.008-1.11 0-2.22 0-3.33 0-.162.1-.365.269-.435l5.447-2.25c.094-.04.187-.053.274-.047zM11 4.79H9v1.975H7V4.79H5v5.926h2V8.74h2v1.976h2V4.79z"
                                  transform="translate(-236 -2916) translate(0 1408) translate(24 1020) translate(16 216) translate(0 256) translate(196 16)"
                                />
                              </g>
                            </g>
                          </g>
                        </g>
                      </g>
                    </g>
                  </g>
                </svg>
                <span>검토 완료</span>
              </span>
            </div>
          ) : (
            <div className={styles.noReduction}>
              기재해주신 내용과 동일하여, 감가없이 거래되었습니다.
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Review;
