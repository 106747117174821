import React from "react";
import styles from "./InspectorFieldSet.module.scss";
import { Item } from "../../../declaration/cars";
import { get, map } from "lodash";

interface Props {
  item: Item;
}

const InspectorFieldSet: React.FC<Props> = ({ item }) => {
  const label = get(item, "label");
  const values = get(item, "values");

  return (
    <div className={styles.fieldSet}>
      <span className={styles.label}>{label}</span>
      <div className={styles.values}>
        {map(values, (value) => {
          if (value) {
            return (
              <span
                dangerouslySetInnerHTML={{ __html: value }}
                className={styles.value}
              />
            );
          }
        })}
      </div>
    </div>
  );
};

export default InspectorFieldSet;
