import React, { ReactNode, useEffect, useState } from "react";
import styles from "./FaqCollapse.module.scss";
import classNames from "classnames";
import useMeasure from "react-use-measure";
import { get } from "lodash";
import { ResizeObserver } from "@juggle/resize-observer";
import LightInk from "../LightInk/LightInk";

interface Props {
  title: string;
  content: string;
  collapseNumber: number;
  openCollapse: number | null;
  setOpenCollapse: (collapse: number | null) => void;
  className?: string;
}
const FaqCollapse: React.FC<Props> = ({
  title,
  content,
  collapseNumber,
  openCollapse,
  setOpenCollapse,
  className,
}) => {
  const [isOpen, setOpen] = useState(false);
  const [contentHeight, setContentHeight] = useState(0);
  const [ref, bounds] = useMeasure({ polyfill: ResizeObserver });
  const height = get(bounds, "height");

  const onClick = () => {
    setOpenCollapse(collapseNumber);
  };

  useEffect(() => {
    if (openCollapse === collapseNumber) {
      setOpen(true);
    } else {
      setOpen(false);
    }
  }, [openCollapse]);

  useEffect(() => {
    setContentHeight(height);
    window.addEventListener("resize", () => setContentHeight(height));
    return window.removeEventListener("resize", () => setContentHeight(height));
  }, [height]);

  return (
    <div
      className={classNames(
        styles.faqCollapse,
        { [styles.isOpen]: isOpen },
        className
      )}
      onClick={onClick}
    >
      <div className={styles.titleWrapper}>
        <div className={styles.title}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            viewBox="0 0 16 16"
            className={classNames(styles.icon)}
          >
            <g fill="#396EFF">
              <path d="M8 2.667c2.946 0 5.333 2.387 5.333 5.333S10.946 13.333 8 13.333 2.667 10.946 2.667 8 5.054 2.667 8 2.667zm0 1.666C5.975 4.333 4.333 5.975 4.333 8S5.975 11.667 8 11.667 11.667 10.025 11.667 8 10.025 4.333 8 4.333z" />
              <path d="M9.077 9.077c.304-.303.784-.324 1.111-.06l.068.06 2.828 2.829c.326.325.326.853 0 1.178-.303.304-.783.324-1.11.061l-.068-.06-2.829-2.83c-.325-.325-.325-.852 0-1.178z" />
            </g>
          </svg>
          <div>{title}</div>
        </div>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          viewBox="0 0 16 16"
          className={styles.arrowIcon}
        >
          <path
            fill="#AFC2DB"
            d="M12.146 5.646L12.854 6.354 8 11.207 3.146 6.354 3.854 5.646 8 9.793z"
          />
        </svg>
        <LightInk />
      </div>
      <div
        className={styles.contentWrapper}
        style={
          isOpen
            ? { height: contentHeight, opacity: 1 }
            : { height: 0, opacity: 0 }
        }
      >
        <div
          className={styles.content}
          ref={ref}
          dangerouslySetInnerHTML={{ __html: content }}
        />
      </div>
    </div>
  );
};

export default FaqCollapse;
