import React, { forwardRef, HTMLAttributes } from "react";
import styles from "./InspectCarInfo.module.scss";
import classNames from "classnames";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import { get, map, size } from "lodash";
import InspectorCarInfoSection from "./InspectorCarInfoSection/InspectorCarInfoSection";
import { CarsTypes } from "../../store/cars";
import CarInfoPlaceHolder from "../CarInfoPlaceHolder/CarInfoPlaceHolder";

interface Props {}

const InspectCarInfo: React.ForwardRefRenderFunction<
  HTMLDivElement,
  Props & HTMLAttributes<HTMLDivElement>
> = ({}, ref) => {
  const { inspectorCarInfo } = useTypedSelector(
    ({ cars: { inspectorCarInfo }}) => {
      return {
        inspectorCarInfo,
      };
    }
  );

  const carInfoSections = get(inspectorCarInfo, "car_info_sections");
  const carInfoSectionsLength = size(carInfoSections);
  return (
    <div className={classNames(styles.inspectCarInfo)} ref={ref}>
      <div>
        {map(carInfoSections, (carInfoSection, index) => (
          <>
            <InspectorCarInfoSection
              key={index}
              carInfoSection={carInfoSection}
            />
            {index !== carInfoSectionsLength - 1 && (
              <div className={styles.border} />
            )}
          </>
        ))}
      </div>
    </div>
  );
};

export default forwardRef(InspectCarInfo);
