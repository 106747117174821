import axios from "axios";
import { Chart } from "src/components/Chart";
import { ChartData } from "src/declaration/ChartData";

export class Engine {
  hashId: string;
  isReady: boolean = false;
  chart: Chart;
  data: ChartData | null;
  isReloading: boolean = false;
  onEndAnimation: () => void;

  constructor(onEndAnimation: () => void) {
    this.onEndAnimation = onEndAnimation
    this.chart = new Chart(this.onEndAnimation);
  }

  async init(hashId: string) {
    this.hashId = hashId;
    this.data = await this.loadData();
    if (!this.data) {
      return;
    }

    this.chart.initializeApp(this.data);

    this.play();
  }

  play() {
    this.chart.play();
  }

  pause() {
    this.chart.pause();
  }

  destroy() {
    this.chart.destroy();
  }

  async reload() {
    if (this.isReloading) {
      return;
    }

    this.isReady = false;
    this.isReloading = true;
    this.chart.destroy();

    this.data = await this.loadData();
    if (!this.data) {
      return;
    }

    this.chart = new Chart(this.onEndAnimation);
    this.chart.initializeApp(this.data);


    this.play();

    this.isReloading = false;
  }

  async loadData(): Promise<ChartData | null> {
    try {
      const { data } = await axios.get(`/customers/web/price_result/cars/${this.hashId}/price_chart/`);
      return data;
    } catch (e) {
      return null;
    }
  }
}
