import FastVector from 'fast-vector';

export function normalizePositions(positions: Array<FastVector>, radius: number): Array<FastVector> {
  if (positions.length <= 2) {
    return positions
  }

  const deletePositionIndexes: Array<number> = [];

  for (let i = 0; i < positions.length; i++) {
    for (let j = 0; j < positions.length; j++) {
      if (i !== j) {
        if (deletePositionIndexes.indexOf(j) < 0 && positions[i].distance(positions[j]) <= radius * 0.1) {
          deletePositionIndexes.push(j);
        }
      }
    }
  }

  const nextPositions: Array<FastVector> = [];

  for (let i = 0; i < positions.length; i++) {
    if (deletePositionIndexes.indexOf(i) < 0) {
      nextPositions.push(positions[i]);
    }
  }

  return nextPositions;
}
