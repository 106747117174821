import axios, { AxiosResponse } from "axios";
import produce from "immer";
import { handleActions } from "redux-actions";
import createAsyncSagaAction from "../cores/createAsyncSagaAction";
import { createAsyncSagaReducerMap } from "../cores/createAsyncSagaReducerMap";
import { PayloadAction } from "typesafe-actions";
import { CarInfo, ReviewSection, SimilarCars } from "../declaration/cars";

export enum CarsTypes {
  getCarInfo = "@cars/getCarInfo",
  getSimilarCars = "@cars/getSimilarCars",
  getReviews = "@cars/getReviews",
  getInspectorCarInfo = "@cars/getInspectorCarInfo",
}

export interface CarsState {
  car: CarInfo | null;
  similarCars: SimilarCars | null;
  reviewSection: ReviewSection | null;
  inspectorCarInfo: CarInfo | null;
}

export const CarsActions = {
  getCarInfo: createAsyncSagaAction(CarsTypes.getCarInfo, (hash_id: string) => {
    return axios.get(`/customers/web/price_result/cars/${hash_id}`);
  }),
  getSimilarCars: createAsyncSagaAction(
    CarsTypes.getSimilarCars,
    (hash_id: string) => {
      return axios.get(
        `/customers/web/price_result/cars/${hash_id}/similar_cars/`
      );
    }
  ),
  getReviews: createAsyncSagaAction(CarsTypes.getReviews, (hash_id: string) => {
    return axios.get(`/customers/web/price_result/cars/${hash_id}/reviews/`);
  }),
  getInspectorCarInfo: createAsyncSagaAction(
    CarsTypes.getInspectorCarInfo,
    (hash_id: string) => {
      return axios.get(`/inspectors/cars/${hash_id}/car_info/`);
    }
  ),
};

const initialState: CarsState = {
  car: null,
  similarCars: null,
  reviewSection: null,
  inspectorCarInfo: null,
};

export default handleActions<CarsState, any>(
  {
    ...createAsyncSagaReducerMap(CarsTypes.getCarInfo, {
      onSuccess: (
        state,
        action: PayloadAction<string, AxiosResponse<CarInfo>>
      ) => {
        return produce(state, (draft) => {
          draft.car = action.payload.data;
        });
      },
    }),
    ...createAsyncSagaReducerMap(CarsTypes.getSimilarCars, {
      onSuccess: (
        state,
        action: PayloadAction<string, AxiosResponse<SimilarCars>>
      ) => {
        return produce(state, (draft) => {
          draft.similarCars = action.payload.data;
        });
      },
    }),
    ...createAsyncSagaReducerMap(CarsTypes.getReviews, {
      onSuccess: (
        state,
        action: PayloadAction<string, AxiosResponse<ReviewSection>>
      ) => {
        return produce(state, (draft) => {
          draft.reviewSection = action.payload.data;
        });
      },
    }),
    ...createAsyncSagaReducerMap(CarsTypes.getInspectorCarInfo, {
      onSuccess: (
        state,
        action: PayloadAction<string, AxiosResponse<CarInfo>>
      ) => {
        return produce(state, (draft) => {
          draft.inspectorCarInfo = action.payload.data;
        });
      },
    }),
  },
  initialState
);
