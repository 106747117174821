import React from "react";
import styles from "./FieldSet.module.scss";
import Typography from "../../Typograph/Typography";
import { Item } from "../../../declaration/cars";
import { get, map } from "lodash";
import classNames from "classnames";
import { useTransition, animated } from "react-spring";

interface Props {
  item: Item;
  isOpen: boolean;
}
const FieldSet: React.FC<Props> = ({ item, isOpen }) => {
  const key = get(item, "key");
  const label = get(item, "label");
  const values = get(item, "values");

  const transitions = useTransition(
    isOpen ||
      (!isOpen && key === "car_name") ||
      key === "year" ||
      key === "mileage",
    null,
    {
      from: { opacity: 0 },
      enter: { opacity: 1 },
      leave: { opacity: 0 },
      config: { duration: 400 },
    }
  );
  return (
    <>
      {transitions.map(
        ({ item, props }) =>
          item && (
            <animated.div style={props} className={styles.fieldSet}>
              <span className={styles.label}>{label}</span>
              <div className={styles.values}>
                {map(values, (value) => {
                  if (value) {
                    return (
                      <span
                        dangerouslySetInnerHTML={{ __html: value }}
                        className={styles.value}
                      />
                    );
                  }
                })}
              </div>
            </animated.div>
          )
      )}
    </>
  );
};

export default FieldSet;
