import { ChartData } from "src/declaration/ChartData";
import { get, chain } from "lodash";
import tooltipStyles from "src/styles/tooltip.module.scss";
import warningStyles from "src/styles/warning.module.scss";
import axios from "axios";
import Warning from "../styles/ic_warning_yellow.svg";

// @ts-ignore
import PolynomialRegression from "js-polynomial-regression";
import { getDevicePixelRatio } from "src/cores/getDevicePixelRatio";
import { Graphics } from "src/components/Graphics";

import {
  AnimationLine,
  AnimationText,
  AnimationBezierCurveLine,
  AnimationCircle,
  AnimationRippleCircle,
  AnimationImage,
  AnimationRangeText,
} from "src/cores/graphics";
import { Rect } from "src/components/Rect";
import { toLocaleString } from "src/cores/toLocaleString";
import FastVector from "fast-vector";
import { Rectangle, Text } from "canvas-object";
import { AssetManager } from "src/components/AssetManager";
import { isIos } from "src/cores/isIos";
import { normalizePositions } from "src/cores/normalizePositions";
import Cookie from "js-cookie";
import { doc } from "prettier";

const marginTop = 164;
const marginLeft = 80;
const marginRight = 24;
const marginBottom = 50;

class Polygon {
  points: Array<FastVector> = [];

  constructor(points: Array<FastVector>) {
    this.points = points;
  }
}

function isInside(vector: FastVector, polygon: Polygon) {
  let crosses = 0;
  const { points } = polygon;
  const pointLength = points.length;
  for (let i = 0; i < pointLength; i++) {
    const j = (i + 1) % pointLength;
    if (points[i].y > vector.y !== points[j].y > vector.y) {
      const atX =
        ((points[j].x - points[i].x) * (vector.y - points[i].y)) /
          (points[j].y - points[i].y) +
        points[i].x;
      if (vector.x < atX) {
        crosses++;
      }
    }
  }
  return crosses % 2 > 0;
}

interface Car {
  price: number;
  mileage: number;
  year: number;
  isHighlight: boolean;
}

const query = new URLSearchParams(window.location.search);
export class Chart {
  data: ChartData;
  canvas: HTMLCanvasElement;
  canvas2: HTMLCanvasElement;
  canvasWidth: number;
  canvasSize: Rect;
  context: CanvasRenderingContext2D;
  context2: CanvasRenderingContext2D;
  resolution: number;
  logged: boolean = false;

  layers: Array<Graphics> = [];
  animationFrameId: number | null = null;
  delta: number = 0;
  startedAt: number = 0;

  range: [number, number] | null = null;
  rangeText: AnimationRangeText;
  standardText: AnimationText;

  blueText: AnimationText;
  blueTextSymbol: AnimationCircle;

  grayText: AnimationText;
  grayTextSymbol1: AnimationCircle;
  grayTextSymbol2: AnimationCircle;
  isAutoplay: boolean = query.get("autoplay") === "true";
  isDebug: boolean = query.get("debug") === "true";

  questionMarkImage: AnimationImage;

  tooltip: HTMLElement | null;
  warning: HTMLElement | null;
  isChartAnimationStarted: boolean;
  onEndAnimation: () => void;
  constructor(onEndAnimation: () => void) {
    this.resolution = getDevicePixelRatio();

    this.canvas = document.createElement("canvas");
    this.canvas2 = document.createElement("canvas");

    this.canvasWidth = window.innerWidth >= 640 ? 640 : window.innerWidth;
    this.canvasSize = new Rect(this.canvasWidth, 408);

    this.context = this.canvas.getContext("2d")!;
    this.context2 = this.canvas2.getContext("2d")!;

    const chartElement = document.getElementById("chart");
    if (chartElement) {
      chartElement.append(this.canvas, this.canvas2);
    }

    this.layers = [
      new Graphics({ context: this.context }),
      new Graphics({ context: this.context }),
      new Graphics({ context: this.context }),
      new Graphics({ context: this.context2 }),
      new Graphics({ context: this.context2 }),
    ];

    this.handleResize(null);
    this.canvas.addEventListener("mousedown", this.handleMouseDown);
    this.canvas2.addEventListener("mousedown", this.handleMouseDown);
    this.canvas2.addEventListener("mousemove", this.handleMouseMove);
    this.isChartAnimationStarted = false;
    this.onEndAnimation = onEndAnimation;
  }

  public handleMouseDown = (e: MouseEvent) => {
    if (this.questionMarkImage && this.tooltip) {
      const { x, y } = this.canvas.getBoundingClientRect();
      const distance = this.questionMarkImage.position
        .add(8, 8)
        .distance(new FastVector(e.clientX - x, e.clientY - y));
      if (get(this.data.legend, "gray") && distance <= 12 * this.resolution) {
        this.tooltip.style.display = "flex";
        this.tooltip.style.transform = `translate(${
          this.questionMarkImage.position.x - this.tooltip.offsetWidth + 30 + x
        }px, ${this.questionMarkImage.position.y + 22 + y + window.scrollY}px)`;
      } else {
        this.tooltip.style.display = "none";
      }
    }
  };
  public handleMouseMove = (e: MouseEvent) => {
    if (this.questionMarkImage) {
      const { x, y } = this.canvas.getBoundingClientRect();
      const distance = this.questionMarkImage.position
        .add(8, 8)
        .distance(new FastVector(e.clientX - x, e.clientY - y));
      if (get(this.data.legend, "gray") && distance <= 12 * this.resolution) {
        this.canvas2.style.cursor = "pointer";
      } else {
        this.canvas2.style.cursor = "auto";
      }
    }
  };

  public handleEnterFrame = () => {
    this.animationFrameId = window.requestAnimationFrame(this.handleEnterFrame);

    const currentTime = Date.now();
    this.delta = (currentTime - this.startedAt) * 0.001;
    this.startedAt = currentTime;

    if (this.standardText && this.rangeText) {
      this.standardText.position.x = this.rangeText.getWidth(this.context) + 28;
    }

    if (
      !this.layers[0].isEndAnimation() ||
      !this.layers[1].isEndAnimation() ||
      !this.layers[2].isEndAnimation()
    ) {
      this.context.clearRect(0, 0, this.canvas.width, this.canvas.height);

      this.isChartAnimationStarted = true;
      for (let i = 0; i < 3; i++) {
        this.layers[i].render();
        this.layers[i].update(this.delta);
      }
    }

    if (
      this.layers[0].isEndAnimation() &&
      this.layers[1].isEndAnimation() &&
      this.layers[2].isEndAnimation()
    ) {
      if (this.isChartAnimationStarted) {
        this.onEndAnimation();
        this.isChartAnimationStarted = false;
      }
    }

    if (!this.layers[3].isEndAnimation() || !this.layers[4].isEndAnimation()) {
      this.context2.clearRect(0, 0, this.canvas.width, this.canvas.height);

      for (let i = 3, length = this.layers.length; i < length; i++) {
        this.layers[i].render();
        this.layers[i].update(this.delta);
      }
    }
  };

  public handleResize = (e: Event | null) => {
    this.canvasSize.width = this.canvasWidth;
    this.canvas.setAttribute(
      "width",
      (this.canvasSize.width * this.resolution).toString()
    );
    this.canvas2.setAttribute(
      "width",
      (this.canvasSize.width * this.resolution).toString()
    );
    this.canvas.setAttribute(
      "height",
      (this.canvasSize.height * this.resolution).toString()
    );
    this.canvas2.setAttribute(
      "height",
      (this.canvasSize.height * this.resolution).toString()
    );

    this.canvas.style.width = `${this.canvasSize.width}px`;
    this.canvas.style.height = `${this.canvasSize.height}px`;
    this.canvas2.style.width = `${this.canvasSize.width}px`;
    this.canvas2.style.height = `${this.canvasSize.height}px`;

    this.canvas.style.position = "absolute";
    this.canvas2.style.position = "absolute";

    if (e) {
      this.layers = [
        new Graphics({ context: this.context }),
        new Graphics({ context: this.context }),
        new Graphics({ context: this.context }),
        new Graphics({ context: this.context2 }),
        new Graphics({ context: this.context2 }),
      ];

      this.initializeGraphics();
      if (this.tooltip) {
        this.tooltip.style.transform = `translate(${
          this.questionMarkImage.position.x - this.tooltip.offsetWidth + 30
        }px, ${this.questionMarkImage.position.y + 22}px)`;
      }
    }
  };

  public initializeNoItem() {
    this.layers[2].add(
      new AnimationText({
        position: new FastVector(24, 32),
        content: "내차 예상시세",
        color: "#272e40",
        scale: this.resolution,
        fontSize: 20,
        textBaseline: "top",
        fontFamily: "Spoqa Han Sans",
        fontWeight: "bold",
      })
    );

    const noItemCar = AssetManager.get("no-item-car");

    if (noItemCar !== null) {
      this.layers[3].add(
        new AnimationImage({
          image: noItemCar,
          position: new FastVector(this.canvasSize.width * 0.5 - 64, 148),
          width: 128,
          height: 80,
          scale: this.resolution,
          delay: 300,
        })
      );
    }

    this.layers[3].add(
      new AnimationText({
        position: new FastVector(this.canvasSize.width * 0.5, 244),
        content: "앗! 최근 같은 모델의 견적 데이터가 없네요.",
        color: "#869ab7",
        scale: this.resolution,
        fontSize: 14,
        textBaseline: "top",
        textAlign: "center",
        fontFamily: "Spoqa Han Sans",
        fontWeight: "normal",
        delay: 600,
      }),
      new AnimationText({
        position: new FastVector(this.canvasSize.width * 0.5, 268),
        content: "죄송하게도 예상시세를 알려드리기 어려워요.",
        color: "#869ab7",
        scale: this.resolution,
        fontSize: 14,
        textBaseline: "top",
        textAlign: "center",
        fontFamily: "Spoqa Han Sans",
        fontWeight: "normal",
        delay: 750,
      }),
      new AnimationText({
        position: new FastVector(this.canvasSize.width * 0.5, 316),
        content: "직접 딜러견적을 받아보시는 건 어떨까요?",
        color: "#869ab7",
        scale: this.resolution,
        fontSize: 14,
        textBaseline: "top",
        textAlign: "center",
        fontFamily: "Spoqa Han Sans",
        fontWeight: "normal",
        delay: 900,
      })
    );
  }

  public async initializeApp(data: ChartData) {
    await AssetManager.loadAll([
      AssetManager.create("pin-mark", "/u_irsc_pin.png"),
      AssetManager.create("question-mark", "/ic_help_gray.png"),
      AssetManager.create("no-item-car", "/noitem_car.png"),
    ]);

    this.data = data;
    this.initializeGraphics();
    this.initializeTooltip();

    if (this.isAutoplay) {
      this.play();
    }
  }

  public play() {
    if (this.animationFrameId !== null) {
      return;
    }

    this.startedAt = Date.now();
    this.animationFrameId = requestAnimationFrame(this.handleEnterFrame);

    if (this.warning) {
      const warnings = document.body.getElementsByClassName(
        this.warning.className
      );

      for (let i = 0; i < warnings.length; i++) {
        warnings[i].className = [
          warningStyles.warning,
          warningStyles.play,
        ].join(" ");
      }
    }
  }

  public pause() {
    if (typeof this.animationFrameId === "number") {
      cancelAnimationFrame(this.animationFrameId);
      this.animationFrameId = null;
    }
  }

  public initializeTooltip() {
    const tooltip = document.createElement("div");
    const title = document.createElement("div");
    const content = document.createElement("div");
    const legendGray = get(this.data, ["legend", "gray"]);
    const year = get(legendGray, "year");
    const carMeta = get(legendGray, "car_meta");

    title.innerText = "비슷한 차량";
    title.className = tooltipStyles.title;

    content.innerText = [year, carMeta]
      .filter((i) => i !== null)
      .map((i) => `· ${i}`)
      .join("\n");
    content.className = tooltipStyles.content;

    tooltip.appendChild(title);
    tooltip.appendChild(content);
    tooltip.className = tooltipStyles.tooltip;
    tooltip.style.display = "none";

    this.tooltip = tooltip;

    document.body.appendChild(tooltip);
  }

  public initializeWarning(isEtc: boolean) {
    if (this.warning) {
      const warnings = document.body.getElementsByClassName(
        this.warning.className
      );

      for (let i = 0; i < warnings.length; i++) {
        warnings[i].remove();
      }
    }

    this.warning = document.createElement("div");
    this.warning.style.backgroundImage = `url(${Warning})`;
    this.warning.innerHTML = `${
      isEtc
        ? "화물·특장·기타 차량은 시세계산이 불가능합니다."
        : "시세계산을 위한 데이터가 부족해요."
    }<br /><b>내차견적 받기</b>로 직접 견적을 받아보시겠어요?`;
    this.warning.className = warningStyles.warning;
    document.getElementById("chart")?.appendChild(this.warning);

    if (typeof this.animationFrameId === "number") {
      this.warning.className = [warningStyles.warning, warningStyles.play].join(
        " "
      );
    }
  }

  public initializeGraphics() {
    const legend = get(this.data, "legend");
    const car = get(this.data, "car");
    const mileage = get(car, "mileage");
    const isEtc = get(car, "is_etc");
    const isTrendLineVisible = get(this.data, "is_trend_line_visible");
    const data = get(this.data, "data");
    const length = chain(data).get("price").size().value();

    let cars: Array<Car> = [];

    for (let i = 0; i < length; i++) {
      cars.push({
        price: get(data, ["price", i]),
        mileage: get(data, ["mileage", i]),
        year: get(data, ["year", i]),
        isHighlight: get(data, ["is_highlight", i]),
      });
    }

    let minMileage = 0;
    let maxMileage = 0;

    let priceRange: number | null = null;
    let lineCount: number | null = null;

    let xCutoffLine = false;
    let yCutoffLine = false;
    let startPrice: number = 0;

    const dataMax = chain(cars)
      .map((car) => car.price)
      .max()
      .value();

    const dataMin = chain(cars)
      .map((car) => car.price)
      .min()
      .value();

    const getCarSize = (comparative: (car: Car) => boolean): number =>
      chain(cars).filter(comparative).size().value();

    if (isEtc) {
      minMileage = 0;
      maxMileage = 300000;
    } else {
      if (getCarSize((car) => car.mileage >= 150000) <= 0) {
        maxMileage = 150000;

        if (getCarSize((car) => car.mileage >= 100000) <= 0) {
          maxMileage = 100000;
        }

        if (getCarSize((car) => car.mileage >= 50000) <= 0) {
          maxMileage = 50000;
        }
      } else if (
        getCarSize((car) => car.mileage >= 150000) > 0 &&
        getCarSize((car) => car.mileage < 100000) <= 0
      ) {
        if (getCarSize((car) => car.mileage < 150000) > 0) {
          xCutoffLine = true;
          minMileage = 100000;
        } else {
          xCutoffLine = true;
          minMileage = 150000;
        }

        if (getCarSize((car) => car.mileage >= 200000) <= 0) {
          maxMileage = 200000;
        } else {
          maxMileage = 300000;
        }
      } else {
        if (getCarSize((car) => car.mileage < 50000) <= 0) {
          xCutoffLine = true;
          minMileage = 50000;
        } else {
          minMileage = 0;
        }

        if (getCarSize((car) => car.mileage >= 200000) <= 0) {
          maxMileage = 200000;
        } else if (getCarSize((car) => car.mileage >= 200000) > 0) {
          maxMileage = 250000;
        } else if (getCarSize((car) => car.mileage >= 250000) > 0) {
          maxMileage = 300000;
        }
      }
    }

    if (dataMin < 200) {
      if (dataMax <= 100) {
        lineCount = 5;
        priceRange = 20;
      } else if (dataMax > 100 && dataMax <= 150) {
        lineCount = 3;
        priceRange = 50;
      } else if (dataMax > 150 && dataMax <= 200) {
        lineCount = 4;
        priceRange = 50;
      } else if (dataMax > 200 && dataMax <= 250) {
        lineCount = 5;
        priceRange = 50;
      } else if (dataMax > 250 && dataMax <= 300) {
        lineCount = 3;
        priceRange = 100;
      } else if (dataMax > 300 && dataMax <= 400) {
        lineCount = 4;
        priceRange = 100;
      } else if (dataMax > 400 && dataMax <= 500) {
        lineCount = 5;
        priceRange = 100;
      } else if (dataMax > 500 && dataMax <= 600) {
        lineCount = 3;
        priceRange = 200;
      } else if (dataMax > 600 && dataMax <= 800) {
        lineCount = 4;
        priceRange = 200;
      } else if (dataMax > 800) {
        const roundDataMax = Math.round(dataMax / 100) * 100;
        lineCount = 5;
        priceRange = Math.round(roundDataMax / 4 / 100) * 100;

        if (priceRange * 4 > dataMax) {
          lineCount = 4;
        } else if (priceRange * 3 > dataMax) {
          lineCount = 3;
        }
      }
    } else if (dataMin >= 200) {
      const roundDataMax = Math.round(dataMax / 100) * 100;
      const floorDataMin = Math.floor(dataMin / 100) * 100;
      const dataMid = roundDataMax - floorDataMin;
      const divideByThreeDataMid = dataMid / 3;

      startPrice = floorDataMin;
      yCutoffLine = priceRange !== startPrice;

      if (divideByThreeDataMid < 50) {
        lineCount = 5;
        priceRange = 50;

        if (startPrice + priceRange * 4 >= dataMax) {
          lineCount = 4;
        } else if (startPrice + priceRange * 3 >= dataMax) {
          lineCount = 3;
        }
      } else {
        lineCount = 5;
        priceRange = Math.ceil(divideByThreeDataMid / 100) * 100;

        if (startPrice + priceRange * 4 >= dataMax) {
          lineCount = 4;
        } else if (startPrice + priceRange * 3 >= dataMax) {
          lineCount = 3;
        }
      }
    }

    if (lineCount === null || priceRange === null) {
      this.initializeNoItem();
      return;
    }

    let startMileage = minMileage / 50000;
    let horizontalLineCount = (maxMileage - minMileage) / 50000;

    if (xCutoffLine) {
      startMileage = startMileage - 1;
      horizontalLineCount = horizontalLineCount + 1;
    }

    startMileage = startMileage * 5;

    const mileageLineWidth = this.canvasSize.width - (marginLeft + marginRight);
    const mileageLineOneWidth = mileageLineWidth / horizontalLineCount;

    const adjointLineWidth = this.canvasSize.width - marginRight;
    const adjointLineHeight = this.canvasSize.height - marginTop - marginBottom;
    const adjointLineOneHeight = adjointLineHeight / lineCount;
    const endAdjointLineHeight = adjointLineHeight + marginTop;

    for (let i = 0; i < horizontalLineCount + 1; i++) {
      const x = marginLeft + i * mileageLineOneWidth;

      this.layers[1].add(
        new AnimationLine({
          position: new FastVector(x, endAdjointLineHeight - 5),
          endPosition: new FastVector(x, endAdjointLineHeight),
          color: "#e9edf4",
          lineWidth: 1,
          scale: this.resolution,
          delay: i * 100,
        }),
        new AnimationText({
          position: new FastVector(x, endAdjointLineHeight + 8),
          textAlign: "center",
          textBaseline: "top",
          content: i === 0 ? "0" : `${i * 5 + startMileage}만km`,
          fontSize: 10,
          fontFamily: "Spoqa Han Sans",
          fontWeight: "normal",
          color: "#afc2db",
          delay: i * 100 + 200,
          scale: this.resolution,
        })
      );
    }

    for (let i = 0; i < lineCount + 1; i++) {
      let price =
        startPrice + priceRange * (lineCount - (yCutoffLine ? 1 : 0) - i);

      if (i == lineCount && yCutoffLine) {
        price = 0;
      }

      const adjointLineY = marginTop + i * adjointLineOneHeight;

      this.layers[1].add(
        new AnimationLine({
          position: new FastVector(marginLeft, adjointLineY),
          endPosition: new FastVector(adjointLineWidth, adjointLineY),
          color: "#e9edf4",
          lineWidth: 1,
          scale: this.resolution,
          delay: i * 100,
        })
      );

      this.layers[2].add(
        new AnimationText({
          position: new FastVector(marginLeft - 8, adjointLineY),
          textAlign: "right",
          textBaseline: "middle",
          content: toLocaleString(price.toFixed(0)) + "만원",
          fontSize: 10,
          fontFamily: "Spoqa Han Sans",
          fontWeight: "normal",
          color: "#afc2db",
          delay: i * 100,
          scale: this.resolution,
        })
      );
    }

    if (yCutoffLine) {
      const positions: Array<FastVector> = [];
      const dotdashedLineSpacing = this.canvasSize.width / 15;
      const adjointLineOneWidth = adjointLineWidth / dotdashedLineSpacing;

      for (let i = 0; ; i++) {
        let x = adjointLineOneWidth * i + marginLeft - 5;
        let y = marginTop + (lineCount - 1 + 0.5) * adjointLineOneHeight;
        let isOverflow = x > adjointLineWidth;

        if (i % 2 === 0) {
          y += 2;
        } else {
          y -= 2;
        }

        positions.push(new FastVector(x, y));

        if (isOverflow) {
          break;
        }
      }

      if (startPrice !== priceRange) {
        this.layers[2].add(
          new AnimationBezierCurveLine({
            positions,
            color: "#e9edf4",
            lineWidth: 4,
            delay: 300,
            scale: this.resolution,
          }),
          new AnimationBezierCurveLine({
            positions,
            color: "#fff",
            lineWidth: 2,
            delay: 300,
            scale: this.resolution,
          })
        );
      }
    }

    if (xCutoffLine && minMileage !== 50000) {
      const positions: Array<FastVector> = [];
      const dotdashedLineSpacing = this.canvasSize.height / 10;
      const mileageLineOneHeight = adjointLineHeight / dotdashedLineSpacing + 8;

      for (let i = 0; ; i++) {
        let x = marginLeft + mileageLineOneWidth * 0.5 - 4;
        let y = i * mileageLineOneHeight - 4 + marginTop;
        let isOverflow = y > adjointLineHeight + marginTop;

        if (i % 2 === 0) {
          x += 2;
        } else {
          x -= 2;
        }

        positions.unshift(new FastVector(x, y));

        if (isOverflow) {
          break;
        }
      }

      this.layers[2].add(
        new AnimationBezierCurveLine({
          positions,
          color: "#e9edf4",
          lineWidth: 4,
          delay: 100,
          scale: this.resolution,
        }),
        new AnimationBezierCurveLine({
          positions,
          color: "#fff",
          lineWidth: 2,
          delay: 100,
          scale: this.resolution,
        })
      );
    }

    const maxPrice =
      priceRange * (lineCount - (yCutoffLine ? 1 : 0)) + startPrice;

    const filteredData = [];

    const isStartPriceChecking = yCutoffLine && startPrice !== priceRange;
    const minPrice = isStartPriceChecking ? startPrice : 0;
    const midPrice = maxPrice - minPrice;
    const midMileage = maxMileage - minMileage;

    const graphWidth =
      mileageLineOneWidth * horizontalLineCount -
      (xCutoffLine ? mileageLineOneWidth : 0);
    const graphHeight =
      adjointLineOneHeight *
      (lineCount - (yCutoffLine && startPrice !== priceRange ? 1 : 0));

    const calcX = (mileage: number) => (mileage - minMileage) / midMileage;
    const calcY = (price: number) => 1 - (price - minPrice) / midPrice;

    let highlightCirclePositions: Array<FastVector> = [];
    let normalCirclePositions: Array<FastVector> = [];

    for (let i = 0, length = cars.length; i < length; i++) {
      let { price, mileage, isHighlight } = cars[i];
      if (
        (yCutoffLine && price < startPrice) ||
        minMileage > mileage ||
        maxMileage < mileage
      ) {
        continue;
      }

      filteredData.push(cars[i]);

      let x = calcX(mileage) * graphWidth;

      if (xCutoffLine) {
        x += mileageLineOneWidth;
      }

      const y = calcY(price) * graphHeight;

      const circlePosition = new FastVector(marginLeft + x, marginTop + y);

      if (isHighlight) {
        (circlePosition as any).price = price;
        highlightCirclePositions.push(circlePosition);
      } else {
        normalCirclePositions.push(circlePosition);
      }
    }

    highlightCirclePositions = normalizePositions(highlightCirclePositions, 3);
    normalCirclePositions = normalizePositions(normalCirclePositions, 12);

    for (let i = 0; i < highlightCirclePositions.length; i++) {
      this.layers[1].add(
        new AnimationCircle({
          position: highlightCirclePositions[i],
          radius: 3,
          color: "#b6caff",
          scale: this.resolution,
          delay: (highlightCirclePositions[i].x / graphWidth) * 750 + 100,
        })
        // new Text({
        //   position: highlightCirclePositions[i],
        //   content: (highlightCirclePositions[i] as any).price.toLocaleString(),
        //   fontFamily: 'Spoqa Han Sans',
        //   textAlign: 'center',
        //   color: "#000",
        //   scale: this.resolution,
        // })
      );
    }

    for (let i = 0; i < normalCirclePositions.length; i++) {
      const delay = (normalCirclePositions[i].x / graphWidth) * 750 + 100;

      this.layers[0].unshift(
        new AnimationCircle({
          position: normalCirclePositions[i],
          radius: 12,
          color: "#e9edf4",
          scale: this.resolution,
          delay: delay,
        })
      );

      this.layers[0].add(
        new AnimationCircle({
          position: normalCirclePositions[i],
          radius: 11,
          color: "#F8F9FB",
          scale: this.resolution,
          delay: delay,
        })
      );
    }

    this.layers[1].add(
      new Rectangle({
        position: new FastVector(0, 0),
        width: marginLeft,
        height: adjointLineHeight + marginTop,
        color: "#fff",
        scale: this.resolution,
      }),
      new Rectangle({
        position: new FastVector(this.canvasSize.width - marginRight, 0),
        width: marginRight,
        height: adjointLineHeight + marginTop,
        color: "#fff",
        scale: this.resolution,
      })
    );

    const model = PolynomialRegression.read(
      chain(filteredData)
        .map((car) => ({ x: car.mileage, y: car.price }))
        .value(),
      3
    );

    const regressionData: Array<{
      price: number;
      mileage: number;
      position: FastVector;
    }> = [];

    const terms = model.getTerms();
    const mileageStandard = 5000;
    const filteredMaxMileage = chain(filteredData)
      .maxBy((car) => car.mileage)
      .get("mileage")
      .value();

    const filteredMinMileage = Math.max(
      chain(filteredData)
        .minBy((car) => car.mileage)
        .get("mileage")
        .value() - mileageStandard,
      mileageStandard
    );

    for (let i = minMileage; i <= maxMileage; i += mileageStandard) {
      if (i < filteredMinMileage || i > filteredMaxMileage) {
        continue;
      }

      let x = calcX(i) * graphWidth;

      if (xCutoffLine) {
        x += mileageLineOneWidth;
      }

      let price = model.predictY(terms, i);
      let y = calcY(price) * graphHeight;

      const position = new FastVector(marginLeft + x, marginTop + y);

      regressionData.push({
        price,
        mileage: i,
        position,
      });
    }

    const suitabilityData: Array<{
      id: number;
      radian: number;
      mileage: number;
      position: FastVector;
      isPassed: boolean;
    }> = [];

    for (let i = 0; i < regressionData.length - 1; i++) {
      const { mileage, position } = regressionData[i];
      const { position: nextPosition } = regressionData[i + 1];

      const radian = position.angleBetween(nextPosition);
      const degree = (radian * 180) / Math.PI;
      const isPassed = degree >= 0 && degree <= 90;

      suitabilityData.push({
        id: i,
        radian,
        mileage,
        position: position.clone(),
        isPassed,
      });
    }

    if (suitabilityData.length > 0) {
      suitabilityData.push({
        id: suitabilityData.length,
        radian: 0,
        mileage: regressionData[regressionData.length - 1].mileage,
        position: regressionData[regressionData.length - 1].position.clone(),
        isPassed: suitabilityData[suitabilityData.length - 1].isPassed,
      });
    }

    let prevMaxY: number | null = null;

    for (let i = 0; i < suitabilityData.length; i++) {
      if (prevMaxY === null || prevMaxY < suitabilityData[i].position.y) {
        prevMaxY = suitabilityData[i].position.y;
      } else {
        suitabilityData[i].isPassed = false;
      }
    }

    const passedIndex = chain(suitabilityData)
      .findIndex((i) => i.isPassed)
      .value();
    const failedIndex = chain(suitabilityData)
      .findIndex((i) => !i.isPassed)
      .value();
    const lastPassedIndex = chain(suitabilityData)
      .findLastIndex((i) => i.isPassed)
      .value();
    const lastFailedIndex = chain(suitabilityData)
      .findLastIndex((i) => !i.isPassed)
      .value();

    if (passedIndex !== 0) {
      for (let i = passedIndex; i > failedIndex; i--) {
        suitabilityData[i - 1].radian = chain(suitabilityData)
          .slice(i, i + 6)
          .meanBy((i) => i.radian)
          .value();
      }
    }

    if (lastPassedIndex !== suitabilityData.length - 1) {
      for (let i = lastPassedIndex + 1; i < lastFailedIndex; i++) {
        suitabilityData[i].radian = chain(suitabilityData)
          .slice(i - 6, i)
          .meanBy((i) => i.radian)
          .value();
      }
    }

    if (passedIndex !== 0) {
      for (let i = passedIndex; i > failedIndex; i--) {
        const { position } = suitabilityData[i];
        const { radian } = suitabilityData[i - 1];

        const nextDirection = new FastVector(
          Math.cos(radian),
          Math.sin(radian)
        );
        const nextPosition = position.sub(nextDirection.mul(1000));

        const guideStartPosition = new FastVector(
          suitabilityData[i - 1].position.x,
          -1000
        );
        const guideEndPosition = new FastVector(
          suitabilityData[i - 1].position.x,
          1000
        );

        const intersection = FastVector.intersection(
          guideStartPosition,
          guideEndPosition,
          position,
          nextPosition
        );

        if (!intersection) {
          continue;
        }

        suitabilityData[i - 1].position = intersection;
      }

      if (passedIndex !== -1 && suitabilityData.length > passedIndex + 1) {
        suitabilityData[passedIndex].position = suitabilityData[
          passedIndex - 1
        ].position.lerp(suitabilityData[passedIndex + 1].position, 0.5);
      }
    }

    if (
      lastPassedIndex !== -1 &&
      lastPassedIndex !== suitabilityData.length - 1
    ) {
      for (let i = lastPassedIndex; i < lastFailedIndex; i++) {
        const { position, radian } = suitabilityData[i];

        const nextDirection = new FastVector(
          Math.cos(radian),
          Math.sin(radian)
        );

        const nextPosition = position.add(nextDirection.mul(1000));

        const guideStartPosition = new FastVector(
          suitabilityData[i + 1].position.x,
          -1000
        );
        const guideEndPosition = new FastVector(
          suitabilityData[i + 1].position.x,
          1000
        );

        const intersection = FastVector.intersection(
          guideStartPosition,
          guideEndPosition,
          position,
          nextPosition
        );

        if (!intersection) {
          continue;
        }

        suitabilityData[i + 1].position = intersection;
      }

      if (lastPassedIndex + 2 <= suitabilityData.length - 1) {
        suitabilityData[lastPassedIndex + 1].position = suitabilityData[
          lastPassedIndex + 2
        ].position.lerp(suitabilityData[lastPassedIndex - 1].position, 0.5);
      }
    }

    const suitabilityPositions = chain(suitabilityData)
      .map((data) => data.position)
      .value();

    const suitabilityPolygon = new Polygon([
      ...suitabilityPositions,
      new FastVector(
        suitabilityPositions.length > 0
          ? suitabilityPositions[suitabilityPositions.length - 1].x
          : 0,
        0
      ),
      new FastVector(
        suitabilityPositions.length > 0 ? suitabilityPositions[0].x : 0,
        0
      ),
    ]);

    const topCount = chain(highlightCirclePositions)
      .filter((p) => isInside(p, suitabilityPolygon))
      .size()
      .value();

    const bottomCount = highlightCirclePositions.length - topCount;

    const highlightMinY = chain(highlightCirclePositions)
      .minBy((p) => p.y)
      .get("y")
      .value();
    const highlightMaxY = chain(highlightCirclePositions)
      .maxBy((p) => p.y)
      .get("y")
      .value();

    const iteration = 20;
    let diffY = highlightMaxY - highlightMinY;

    if (topCount > bottomCount) {
      diffY *= -1;
    }

    const oneDiffY = diffY / iteration;
    let realDiff = diffY;
    let prevIterationDiffCount: number | null = null;

    for (let i = 0; i < iteration; i++) {
      const diff = oneDiffY * i;
      const positions = chain(suitabilityData)
        .map((data) => data.position.add(0, diff))
        .value();
      const polygon = new Polygon([
        ...positions,
        new FastVector(
          positions.length > 0 ? positions[positions.length - 1].x : 0,
          0
        ),
        new FastVector(positions.length > 0 ? positions[0].x : 0, 0),
      ]);

      const topCount = chain(highlightCirclePositions)
        .filter((p) => isInside(p, polygon))
        .size()
        .value();

      const bottomCount = highlightCirclePositions.length - topCount;

      const iterationDiffCount = Math.abs(topCount - bottomCount);

      if (prevIterationDiffCount === null) {
        prevIterationDiffCount = iterationDiffCount;
      }

      if (
        prevIterationDiffCount !== null &&
        prevIterationDiffCount > iterationDiffCount
      ) {
        prevIterationDiffCount = iterationDiffCount;
      }

      if (prevIterationDiffCount < iterationDiffCount) {
        realDiff = oneDiffY * (i - 1);
        break;
      }
    }

    const lastSuitabilityPositions = chain(suitabilityData)
      .map((data) => data.position.add(0, realDiff))
      .value();

    for (let i = 0; i < lastSuitabilityPositions.length; i++) {
      const { y } = lastSuitabilityPositions[i];
      let currentPrice = (1 - (y - marginTop) / graphHeight) * midPrice;
      if (isStartPriceChecking) {
        currentPrice += startPrice;
      }

      if (currentPrice < minPrice) {
        lastSuitabilityPositions.splice(i, lastSuitabilityPositions.length - i);
        break;
      }
    }

    const pinMark = AssetManager.get("pin-mark");
    const questionMark = AssetManager.get("question-mark");

    if (questionMark) {
      const blueTextContent = get(legend, "blue");
      const grayTextContent = "비슷한 차량";
      const offsetY = isIos() ? -2 : 0;

      this.blueText = new AnimationText({
        position: new FastVector(0, 0),
        content: blueTextContent,
        color: "#869ab7",
        fontSize: 10,
        textAlign: "left",
        textBaseline: "top",
        scale: this.resolution,
        fontFamily: "Spoqa Han Sans",
        fontWeight: "normal",
      });

      this.grayText = new AnimationText({
        position: new FastVector(0, 0),
        content: grayTextContent,
        color: "#869ab7",
        fontSize: 10,
        textAlign: "left",
        textBaseline: "top",
        scale: this.resolution,
        fontFamily: "Spoqa Han Sans",
        fontWeight: "normal",
      });

      const grayTextWidth = this.grayText.getWidth(this.context);
      const maxWidth = Math.max(
        this.blueText.getWidth(this.context),
        grayTextWidth + 20
      );

      this.blueText.position.x = this.canvasWidth - marginRight - maxWidth;
      this.blueText.position.y = marginTop - 40;
      this.grayText.position.x = this.canvasWidth - marginRight - maxWidth;
      this.grayText.position.y = marginTop - 23.5;

      this.questionMarkImage = new AnimationImage({
        image: questionMark,
        position: new FastVector(
          this.grayText.position.x + grayTextWidth + 4,
          this.grayText.position.y - 3
        ),
        width: 16,
        height: 16,
        scale: this.resolution,
        delay: 300,
      });

      const graySymbolPosition = new FastVector(
        this.grayText.position.x - 10,
        this.grayText.position.y + 6
      );

      const blueSymbolPosition = new FastVector(
        this.blueText.position.x - 10,
        this.blueText.position.y + 6
      );

      this.grayTextSymbol1 = new AnimationCircle({
        position: graySymbolPosition,
        radius: 6,
        color: "#e9edf4",
        scale: this.resolution,
        delay: 600,
      });

      this.grayTextSymbol2 = new AnimationCircle({
        position: graySymbolPosition,
        radius: 5,
        color: "#F8F9FB",
        scale: this.resolution,
        delay: 600,
      });

      this.blueTextSymbol = new AnimationCircle({
        position: blueSymbolPosition,
        radius: 3,
        color: "rgba(57, 110, 255, .34)",
        scale: this.resolution,
        delay: 600,
      });

      this.blueText.position.y += offsetY;
      this.grayText.position.y += offsetY;

      if (get(legend, "gray") !== null) {
        this.layers[4].unshift(this.grayTextSymbol1);
        this.layers[4].add(
          this.grayTextSymbol2,
          this.grayText,
          this.questionMarkImage
        );
      }
      this.layers[4].add(this.blueTextSymbol, this.blueText);
    }

    let selectedX = calcX(mileage) * graphWidth + marginLeft;

    if (xCutoffLine) {
      selectedX += mileageLineOneWidth;
    }

    let selectedPosition: FastVector | null = null;
    let selectedIndex = 0;

    const hasMileageLine =
      selectedX >=
        chain(lastSuitabilityPositions)
          .minBy((p) => p.x)
          .get("x")
          .value() &&
      selectedX <=
        chain(lastSuitabilityPositions)
          .maxBy((p) => p.x)
          .get("x")
          .value();

    const distancePositionIds = chain(lastSuitabilityPositions)
      .map((p, key) => ({ value: Math.abs(p.x - selectedX), id: key }))
      .sortBy((v) => v.value)
      .value();

    const headDistancePositionId = get(distancePositionIds, 0);
    if (
      headDistancePositionId &&
      minMileage <= mileage &&
      maxMileage >= mileage
    ) {
      selectedIndex = headDistancePositionId.id;
      selectedPosition = get(
        lastSuitabilityPositions,
        headDistancePositionId.id
      );
    } else {
      this.range = null;
      this.initializeWarning(isEtc);
    }

    if (
      isTrendLineVisible &&
      !isEtc &&
      selectedPosition &&
      hasMileageLine &&
      pinMark
    ) {
      let selectedPrice =
        (1 - (selectedPosition.y - marginTop) / graphHeight) * midPrice +
        minPrice;

      let y = graphHeight + marginTop - selectedPosition.y;

      if (yCutoffLine && startPrice !== priceRange) {
        y += adjointLineOneHeight;
      }

      const delay = selectedIndex * 50 + 50;

      this.layers[2].add(
        new AnimationLine({
          position: selectedPosition,
          endPosition: selectedPosition.add(0, y),
          color: "#396eff",
          scale: this.resolution,
          segments: [6, 6],
          delay,
        })
      );

      let mileageDisplay = mileage / 10000;
      let mileageDisplay2 = mileageDisplay.toFixed(
        mileageDisplay - Math.floor(mileageDisplay) !== 0 ? 1 : 0
      );

      const dotIndex = mileageDisplay2.indexOf(".");

      if (
        mileageDisplay2[
          dotIndex === -1 ? mileageDisplay2.length - 1 : dotIndex + 1
        ] === "0"
      ) {
        mileageDisplay2 = mileageDisplay2.substr(0, dotIndex);
      }

      if (mileageDisplay2 === "") {
        mileageDisplay2 = mileageDisplay.toString();
      }

      this.layers[2].add(
        new AnimationText({
          content: mileageDisplay2 + "만km",
          position: selectedPosition.add(0, y + 3),
          textAlign: "center",
          textBaseline: "top",
          color: "#396eff",
          scale: this.resolution,
          fontSize: 10,
          fontFamily: "Spoqa Han Sans",
          fontWeight: "bold",
          delay,
        })
      );

      this.layers[3].add(
        new AnimationImage({
          image: pinMark,
          position: selectedPosition.sub(7.5, 23),
          width: 16,
          height: 20,
          scale: this.resolution,
          delay: 300,
        })
      );

      this.layers[3].add(
        new AnimationRippleCircle({
          position: selectedPosition.sub(0, 2),
          radius: 12,
          color: "#396eff",
          alpha: 0.5,
          scale: this.resolution,
          delay: 300,
        })
      );

      const roundedPrice = Math.round(selectedPrice / 10) * 10;
      const rangeMin = Math.max(
        30,
        this.data.car.ab_test.adjust_estimated_price !== "b"
          ? roundedPrice - 50
          : (roundedPrice <= 1950 ? roundedPrice - 100 : roundedPrice - 180)
      );
      const rangeMax = roundedPrice + 50;
      
      this.range = [rangeMin, rangeMax];
      this.rangeText = new AnimationRangeText({
        position: new FastVector(24, 64),
        values: this.range,
        content: "",
        color: "#396eff",
        scale: this.resolution,
        textBaseline: "top",
        fontSize: 32,
        fontFamily: "Spoqa Han Sans",
        fontWeight: "bold",
      });

      this.standardText = new AnimationText({
        position: new FastVector(
          this.rangeText.getWidth(this.context) + 28,
          73
        ),
        content: "만원",
        color: "#396eff",
        scale: this.resolution,
        textAlign: "left",
        textBaseline: "top",
        fontSize: 20,
        fontFamily: "Spoqa Han Sans",
        fontWeight: "bold",
      });

      this.layers[2].add(this.rangeText, this.standardText);
    }

    if (isTrendLineVisible && !isEtc && hasMileageLine) {
      this.layers[2].add(
        new AnimationText({
          position: new FastVector(24, 32),
          content: "내차 예상시세",
          color: "#272e40",
          scale: this.resolution,
          fontSize: 20,
          textBaseline: "top",
          fontFamily: "Spoqa Han Sans",
          fontWeight: "bold",
        })
      );
    } else {
      this.layers[2].add(
        new AnimationText({
          position: new FastVector(24, 32),
          content: "내차 예상시세",
          color: "#272e40",
          scale: this.resolution,
          fontSize: 16,
          textBaseline: "top",
          fontFamily: "Spoqa Han Sans",
          fontWeight: "bold",
        })
      );

      this.range = null;
      this.initializeWarning(isEtc);
    }

    if (isTrendLineVisible) {
      this.layers[2].add(
        new AnimationBezierCurveLine({
          positions: lastSuitabilityPositions,
          color: "#396eff",
          lineWidth: 4,
          lineCap: "round",
          delay: 400,
          scale: this.resolution,
          factor: 0,
        })
      );
    }

    if (this.isDebug) {
      this.isDebug = false;
      const text = document.createTextNode(
        JSON.stringify(this.data, null, "  ")
      );
      const wrapper = document.createElement("div");
      wrapper.style.paddingTop = `408px`;
      wrapper.style.whiteSpace = `pre-wrap`;
      wrapper.style.fontFamily = `Monaco, Roboto`;
      wrapper.style.fontSize = `12px`;
      wrapper.appendChild(text);
      document.body.appendChild(wrapper);
    }

    const token = Cookie.get("csrftoken");
    const logHashId = get(this.data, "log_hash_id");

    if (!this.logged && logHashId && token) {
      this.logged = true;

      const data =
        this.range === null
          ? { is_available: false, range_start: null, range_end: null }
          : {
              is_available: true,
              range_start: this.range[0],
              range_end: this.range[1],
            };
      axios.post(`/v2/customers/log/price_chart/${logHashId}/`, data, {
        headers: { Authorization: `JWT ${token}` },
      });
    }
  }

  public destroy() {
    this.context.clearRect(0, 0, this.canvas.width, this.canvas.height);
    this.context2.clearRect(0, 0, this.canvas.width, this.canvas.height);

    const chart = document.getElementById("chart");
    if (chart) {
      chart.removeChild(this.canvas);
      chart.removeChild(this.canvas2);
    }
    if (this.tooltip) {
      document.body.removeChild(this.tooltip);
    }

    if (this.warning) {
      document.body.removeChild(this.warning);
    }

    if (typeof this.animationFrameId === "number") {
      cancelAnimationFrame(this.animationFrameId);
    }
  }
}
