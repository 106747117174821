/** @jsxImportSource @emotion/react */

import { FC } from "react";
import styles from "./InspectCarInfo.module.scss";
import classNames from "classnames";
import { Divider, Skeleton } from "@PRNDcompany/heydealer-ui";

// 애니메이션 딜레이 버그 등 구버전 스켈레톤 컴포넌트를 사용할 때 발생하는 문제 임시 대응하도록 작성하였음; 최신화 필요
const SkeletonRow = () => (
  <div css={{ display: "grid", gridTemplateColumns: "3.75rem 1fr", gap: "1rem", height: "1.5rem", alignItems: "center" }}>
    <Skeleton loading customCSS={{ width: `${50 * Math.random() + 50}%`, height: "1rem", "div:first-of-type": { animationDelay: "0s!important" } }} />
    <Skeleton loading customCSS={{ width: `${25 * Math.random() + 50}%`, height: "1rem", "div:first-of-type": { animationDelay: "0s!important" } }} />
  </div>
)

// 모든 UI 데이터를 서버에서 받기 때문에 시안 없이 임의 형태로 구현하였음
const InspectCarInfoLoading: FC = () => (
  <div className={classNames(styles.inspectCarInfo)}>
    <div css={{ display: "grid", gap: "1rem" }}>
      <div css={{ display: "grid", gap: "0.75rem" }}>
        {Array(2).fill(<div>{Array(3).fill(<SkeletonRow />)}</div>)}
      </div>

      <Divider />
    
      <div>{Array(3).fill(<SkeletonRow />)}</div>
    
      <Divider />

      <div>{Array(3).fill(<SkeletonRow />)}</div>

      <Divider />
    
      <div css={{ display: "grid", gap: "0.75rem" }}>
        <SkeletonRow />  
        <div>{Array(2).fill(<SkeletonRow />)}</div>
      </div>
    </div>
  </div>
);

export default InspectCarInfoLoading;
