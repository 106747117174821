import React, { useRef } from "react";
import styles from "./ShowMore.module.scss";
import { animated, useChain, useTransition } from "react-spring";
import LightInk from "../../LightInk/LightInk";

interface Props {
  isOpen: boolean;
  onClick: () => void;
}

const ShowMore: React.FC<Props> = ({ isOpen, onClick }) => {
  const transitions = useTransition(isOpen, null, {
    from: { position: "absolute", opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
  });

  return (
    <div className={styles.showMoreWrapper} onClick={onClick}>
      <div className={styles.showMore}>
        {transitions.map(({ item, key, props }) =>
          item ? (
            <animated.div style={props} className={styles.label}>
              <span>접기</span>
              <img
                src="/show_more.svg"
                alt="접기"
                className={styles.reverseArrow}
              />
            </animated.div>
          ) : (
            <animated.div style={props} className={styles.label}>
              <span>더보기</span>
              <img src="/show_more.svg" alt="더보기" />
            </animated.div>
          )
        )}
      </div>
      <LightInk />
    </div>
  );
};

export default ShowMore;
