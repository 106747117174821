import React from "react";
import styles from "./CarNumberPosition.module.scss";
import classNames from "classnames";

interface Props {
  className?: string;
  isInspector: boolean;
}
const CarNumberPosition: React.FC<Props> = ({
  children,
  className,
  isInspector,
}) => {
  return (
    <div
      className={classNames([
        styles.carNumberPosition,
        className,
        { [styles.inspector]: isInspector },
      ])}
    >
      {children}
    </div>
  );
};

export default CarNumberPosition;
