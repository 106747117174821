import React, { useEffect, useRef, useState } from "react";
import styles from "./ServiceInfo.module.scss";
import Typography from "../Typograph/Typography";
import { useInView } from "react-intersection-observer";
import GifPlayer from "react-gif-player";
import classNames from "classnames";

const ServiceInfo: React.FC<{}> = () => {
  const [playGif1, setPlayGif1] = useState(false);
  const [playGif2, setPlayGif2] = useState(false);
  const [service1Ref, service1inView] = useInView({
    threshold: 0,
  });
  const [service2Ref, service2inView] = useInView({
    threshold: 0,
  });

  useEffect(() => {
    if (service1inView) {
      setPlayGif1(true);
      setTimeout(() => {
        setPlayGif1(false);
      }, 1700);
    }
  }, [service1inView]);

  useEffect(() => {
    if (service2inView) {
      setPlayGif2(true);
      setTimeout(() => {
        setPlayGif2(false);
      }, 1700);
    }
  }, [service2inView]);

  return (
    <div className={styles.serviceInfo}>
      <Typography variant="h1" className={styles.title}>
        정확한 견적을 받아보세요.
      </Typography>
      <div className={styles.section} ref={service1Ref}>
        <div className={styles.content}>
          <Typography variant="body1">
            전국딜러 비교로
            <br />
            높은 가격에 팔 수 있어요.
          </Typography>
          <Typography variant="body3" className={styles.description}>
            *전화없이 앱으로 견적도착
          </Typography>
        </div>
        <div className={styles.gifWrapper}>
          <GifPlayer
            autoplay
            gif="/1.gif"
            still="/1.png"
            className={classNames(styles.serviceImage, styles.gif, {
              [styles.stop]: !playGif1,
            })}
          />
          <img
            src="/1.png"
            alt=""
            className={classNames(styles.serviceImage, styles.img, {
              [styles.show]: !playGif1,
            })}
          />
        </div>
      </div>
      <div className={styles.border} />
      <div className={styles.section} ref={service2Ref}>
        <div className={styles.content}>
          <Typography variant="body1">
            부당한 감가는
            <br />
            헤이딜러에서 돌려드립니다.
          </Typography>
          <Typography variant="body3" className={styles.description}>
            *감가심사팀이 거래내용 검토
          </Typography>
        </div>
        <div className={styles.gifWrapper}>
          <GifPlayer
            autoplay
            gif="/2.gif"
            still="/2.png"
            className={classNames(styles.serviceImage, styles.gif, {
              [styles.stop]: !playGif2,
            })}
          />
          <img
            src="/2.png"
            alt=""
            className={classNames(styles.serviceImage, styles.img, {
              [styles.show]: !playGif2,
            })}
          />
        </div>
      </div>
    </div>
  );
};

export default ServiceInfo;
