import React, { useEffect, useRef, useState } from "react";
import styles from "./SimilarCarsModal.module.scss";
import { animated, config, useTransition } from "react-spring";
import { Portal } from "react-portal";
import { SimilarCar as ISimilarCar } from "../../declaration/cars";
import { map, range } from "lodash";
import size from "lodash/size";
import SimilarCar from "../SimilarCars/SimilarCar/SimilarCar";
import LightInk from "../LightInk/LightInk";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { isIos } from "src/cores/isIos";

interface Props {
  isVisible: boolean;
  onClose: () => void;
  cars: ISimilarCar[] | null | undefined;
}

let scrollPosition = 0;
const SimilarCarsModal: React.FC<Props> = ({ isVisible, onClose, cars }) => {
  const [isAnimationEnd, setAnimationEnd] = useState(false);
  const contentWrapperRef = useRef<HTMLDivElement>(null);
  const transitions = useTransition(isVisible, null, {
    from: { opacity: 0, height: "20vh" },
    enter: { opacity: 1, height: isIos() ? "85vh" : "95vh" },
    leave: { opacity: 0, height: "-5vh" },
    config: config.stiff,
    // @ts-ignore
    onRest: (isDone) => {
      setAnimationEnd(isDone);
    },
  });

  const scrollToBottom = () => {
    setTimeout(() => {
      if (contentWrapperRef.current) {
        contentWrapperRef.current.scrollTo(
          0,
          contentWrapperRef.current.scrollHeight
        );
      }
    }, 300);
  };

  const bodyScrollEnable = () => {
    const body = document.querySelector("body");
    if (body) {
      body.style.removeProperty("overflow");
      body.style.removeProperty("position");
      body.style.removeProperty("top");
      body.style.removeProperty("width");
    }
    window.scrollTo(0, scrollPosition);
  };

  const bodyScrollDisable = () => {
    const body = document.querySelector("body");
    if (body) {
      scrollPosition = window.pageYOffset;
      body.style.overflow = "hidden";
      body.style.position = "fixed";
      body.style.top = `-${scrollPosition}px`;
      body.style.width = "100%";
    }
    window.scrollTo(0, scrollPosition);
  };

  useEffect(() => {
    if (isVisible) {
      bodyScrollDisable();
    } else {
      bodyScrollEnable();
    }
  }, [isVisible]);

  return (
    <>
      {transitions.map(
        ({ item, props, key }) =>
          item && (
            <Portal>
              <animated.div
                style={{ opacity: props.opacity, height: '100vh' }}
                className={styles.dimmer}
              >
                <animated.div
                  className={styles.modalContent}
                  style={{ height: props.height }}
                >
                  <div className={styles.header}>
                    <div
                      className={styles.closeButtonWrapper}
                      onClick={onClose}
                    >
                      <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        className={styles.closeButton}
                      >
                        <g fill="none" fillRule="evenodd">
                          <g fill="#272E40" fillRule="nonzero">
                            <g>
                              <g>
                                <g>
                                  <path
                                    d="M18.442 4.604l1.06 1.06-6.47 6.47 6.47 6.47-1.06 1.06-6.47-6.47-6.47 6.47-1.06-1.06 6.47-6.47-6.47-6.47 1.06-1.06 6.47 6.47 6.47-6.47z"
                                    transform="translate(-16 -66) translate(0 50) translate(4 4) translate(12 12)"
                                  />
                                </g>
                              </g>
                            </g>
                          </g>
                        </g>
                      </svg>
                      <LightInk />
                    </div>
                    비슷한 차 견적결과
                  </div>
                  <div
                    ref={contentWrapperRef}
                    className={styles.contentWrapper}
                    style={{ height: '90vh' }}
                  >
                    <div className={styles.body}>
                      <span className={styles.info}>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          viewBox="0 0 16 16"
                        >
                          <g fill="none" fillRule="evenodd">
                            <g fill="#CCD8E8">
                              <g>
                                <path
                                  d="M14 8c0 1.08-.27 2.08-.81 3-.54.92-1.27 1.65-2.19 2.19-.92.54-1.92.81-3 .81s-2.08-.27-3-.81c-.92-.54-1.65-1.27-2.19-2.19C2.27 10.08 2 9.08 2 8s.27-2.08.81-3C3.35 4.08 4.08 3.35 5 2.81 5.92 2.27 6.92 2 8 2s2.08.27 3 .81c.92.54 1.65 1.27 2.19 2.19.54.92.81 1.92.81 3zM8 4.667c-.28 0-.521.1-.72.3-.2.199-.3.439-.3.72 0 .28.1.521.3.72.199.2.44.3.72.3.28 0 .521-.1.72-.3.2-.199.3-.44.3-.72 0-.281-.1-.521-.3-.72-.199-.2-.44-.3-.72-.3zm-.976 3.621l.178 3.016c0 .06.025.115.077.167.052.052.115.077.189.077h1.064c.074 0 .137-.025.189-.077.052-.052.077-.107.077-.167l.178-3.016c0-.088-.026-.159-.078-.21C8.846 8.026 8.784 8 8.71 8H7.29c-.074 0-.136.026-.188.078-.052.051-.078.122-.078.21z"
                                  transform="translate(-237 -636) translate(237 636)"
                                />
                              </g>
                            </g>
                          </g>
                        </svg>
                        <span>주행거리 짧은 순</span>
                      </span>
                      {isAnimationEnd ? (
                        map(cars, (car, index) => (
                          <SimilarCar
                            similarCar={car}
                            isLastCar={index === size(cars) - 1}
                            scrollToBottom={scrollToBottom}
                          />
                        ))
                      ) : (
                        <SkeletonTheme>
                          {map(range(10), (index) => (
                            <div
                              className={styles.similarCarPlaceholder}
                              key={index}
                            >
                              <Skeleton width={56} height={56} />
                              <div className={styles.content}>
                                <div className={styles.firstRow}>
                                  <Skeleton
                                    width={120}
                                    height={16}
                                    className={styles.title}
                                  />
                                  <Skeleton width={50} height={16} />
                                </div>
                                <Skeleton width={160} height={14} />
                              </div>
                            </div>
                          ))}
                        </SkeletonTheme>
                      )}
                    </div>
                  </div>
                </animated.div>
              </animated.div>
            </Portal>
          )
      )}
    </>
  );
};

export default SimilarCarsModal;
