import React, { useEffect, useState } from "react";
import styles from "./SimilarCars.module.scss";
import { useDispatch } from "react-redux";
import { CarsActions, CarsTypes } from "../../store/cars";
import Typography from "../Typograph/Typography";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import { get, map, take } from "lodash";
import SimilarCar from "./SimilarCar/SimilarCar";
import LightInk from "../LightInk/LightInk";
import { useHistory, useLocation } from "react-router-dom";
import SimilarCarsModal from "../SimilarCarsModal/SimilarCarsModal";

interface Props {
  hashId: string;
  hideShowMoreButton?: boolean;
}
const SimilarCars: React.FC<Props> = ({ hashId, hideShowMoreButton = false }) => {
  const { key, pathname, search, state } = useLocation<{ isVisibleSimilarCarModal?: boolean } | undefined>();
  const query = new URLSearchParams(search);
  const [isVisibleSimilarCarModal, setVisibleSimilarCarModal] = useState(state?.isVisibleSimilarCarModal ?? false);
  const dispatch = useDispatch();
  const history = useHistory();

  const { similarCars, isLoading } = useTypedSelector(({ cars: { similarCars }, loading: { asyncMap } }) => {
    return {
      similarCars,
      isLoading: get(asyncMap, CarsTypes.getSimilarCars) > 0,
    };
  });

  const cars = get(similarCars, "cars");

  const onClose = () => {
    history.goBack();
  };

  const onClickShowMore = () => {
    setVisibleSimilarCarModal(true);

    query.set("similar_cars", "true");
    history.push(`${pathname}?${query.toString()}`, { isVisibleSimilarCarModal: true });
  };

  useEffect(() => {
    if (query.get("similar_cars") !== "true") {
      setVisibleSimilarCarModal(false);
    } else if (state?.isVisibleSimilarCarModal) {
      setVisibleSimilarCarModal(true);
    } else if (!isVisibleSimilarCarModal) {
      query.delete("similar_cars");
      history.replace(`${pathname}?${query.toString()}`);

      onClickShowMore();
    }
  }, [key]);

  useEffect(() => {
    dispatch(CarsActions.getSimilarCars(hashId));
  }, []);

  return (
    <div className={styles.similarCars}>
      <div className={styles.header}>
        <Typography variant="h2">비슷한 차 견적결과</Typography>
        <span className={styles.info}>
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
            <g fill="none" fillRule="evenodd">
              <g fill="#CCD8E8">
                <g>
                  <path
                    d="M14 8c0 1.08-.27 2.08-.81 3-.54.92-1.27 1.65-2.19 2.19-.92.54-1.92.81-3 .81s-2.08-.27-3-.81c-.92-.54-1.65-1.27-2.19-2.19C2.27 10.08 2 9.08 2 8s.27-2.08.81-3C3.35 4.08 4.08 3.35 5 2.81 5.92 2.27 6.92 2 8 2s2.08.27 3 .81c.92.54 1.65 1.27 2.19 2.19.54.92.81 1.92.81 3zM8 4.667c-.28 0-.521.1-.72.3-.2.199-.3.439-.3.72 0 .28.1.521.3.72.199.2.44.3.72.3.28 0 .521-.1.72-.3.2-.199.3-.44.3-.72 0-.281-.1-.521-.3-.72-.199-.2-.44-.3-.72-.3zm-.976 3.621l.178 3.016c0 .06.025.115.077.167.052.052.115.077.189.077h1.064c.074 0 .137-.025.189-.077.052-.052.077-.107.077-.167l.178-3.016c0-.088-.026-.159-.078-.21C8.846 8.026 8.784 8 8.71 8H7.29c-.074 0-.136.026-.188.078-.052.051-.078.122-.078.21z"
                    transform="translate(-237 -636) translate(237 636)"
                  />
                </g>
              </g>
            </g>
          </svg>
          <span>주행거리 짧은 순</span>
        </span>
      </div>
      {isLoading ? <div /> : map(take(cars, 3), (car, index) => <SimilarCar similarCar={car} key={index} />)}
      {!hideShowMoreButton && (
        <button className={styles.showMoreButton} onClick={onClickShowMore}>
          <span className={styles.text}>더보기</span>
          <svg xmlns="http://www.w3.org/2000/svg" width="17" height="16" viewBox="0 0 17 16">
            <g fill="none" fillRule="evenodd">
              <g fill="#AFC2DB" fillRule="nonzero">
                <g>
                  <g>
                    <g>
                      <path
                        d="M12.396 5.396L13.104 6.104 8.25 10.957 3.396 6.104 4.104 5.396 8.25 9.543z"
                        transform="translate(-195 -964) translate(24 948) translate(124.5 16) translate(47) rotate(-90 8.25 8.177)"
                      />
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </svg>
          <LightInk />
        </button>
      )}
      <SimilarCarsModal isVisible={isVisibleSimilarCarModal} onClose={onClose} cars={cars} />
    </div>
  );
};

export default SimilarCars;
