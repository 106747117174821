import styles from "./Inspector.module.scss";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { CarsActions, CarsTypes } from "../../store/cars";
import { SequenceActions } from "../../store/sequence";
import CarNumber from "../../components/CarNumber/CarNumber";
import InspectCarInfo from "../../components/InspectCarInfo/InspectCarInfo";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import { get } from "lodash";
import Cookies from "js-cookie";
import axios from "axios";
import InspectCarInfoLoading from "src/components/InspectCarInfo/InspectCarInfo.loading";
import { Skeleton } from "@PRNDcompany/heydealer-ui";

axios.defaults.xsrfCookieName = "csrftoken";
axios.defaults.xsrfHeaderName = "X-CSRFToken";
axios.defaults.withCredentials = true;

export default function Inspector() {
  const [token, setToken] = useState<string | null>(null);
  useEffect(() => {
    const csrftoken = Cookies.get("csrftoken");
    setToken(csrftoken || null);
  }, []);

  const params = useParams<{ hashId: string }>();
  const hashId = params?.hashId;
  const dispatch = useDispatch();
  const { isLoading } = useTypedSelector(({ loading: { asyncMap } }) => {
    return {
      isLoading: get(asyncMap, CarsTypes.getInspectorCarInfo) > 0,
    };
  });
  useEffect(() => {
    if (hashId) {
      dispatch(
        SequenceActions.createSequence([
          CarsActions.getInspectorCarInfo(hashId),
        ])
      );
    }
  }, [hashId]);

  if (!token) {
    return <div className={styles.nonAuthenticated}>인증되지 않은 사용자입니다.</div>;
  }

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        전문 평가사용
        <img src="/inspector_logo.svg" alt="로고" className={styles.logo} />
      </div>

      {isLoading ? (
        // 스타일 임의 구현 + 애니메이션 딜레이 버그 등 구버전 스켈레톤 컴포넌트를 사용할 때 발생하는 문제 임시 대응하도록 작성하였음; 최신화 필요
        <Skeleton loading customCSS={{ position: "fixed", top: "4.375rem", left: "1rem", width: "9.5rem", height: "2rem", borderRadius: "0.25rem", "div:first-of-type": { animationDelay: "0s!important" } }} />
      ) : (
        <CarNumber isInspector />
      )}

      {isLoading ? <InspectCarInfoLoading /> : <InspectCarInfo />}

      <div className={styles.footer}>
        <img src="/inspector_footer.svg" alt="" />
      </div>
    </div>
  );
}
