import React from "react";
import styles from "./InspectorCarInfoSection.module.scss";
import { get, map } from "lodash";
import { CarInfoSections, Item } from "../../../declaration/cars";
import InspectorFieldSet from "../InspectorFieldSet/InspectorFieldSet";

interface Props {
  carInfoSection: CarInfoSections;
}

const InspectorCarInfoSection: React.FC<Props> = ({ carInfoSection }) => {
  const items = get(carInfoSection, "items");

  const renderItems = (item: Item, index: number) => {
    const type = get(item, "type");

    switch (type) {
      case "labeled_text":
        return <InspectorFieldSet item={item} key={index} />;
      case "margin":
        return <div className={styles.margin} key={index} />;
    }
  };
  return <>{map(items, (item, index) => renderItems(item, index))}</>;
};

export default InspectorCarInfoSection;
