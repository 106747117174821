import { combineReducers } from "redux";
import cars, { CarsState } from "./cars";
import loading, { LoadingState } from "./loading";
import sequence, { SequenceState } from "./sequence";

export default combineReducers<Application>({
  cars,
  loading,
  sequence,
});

export interface Application {
  cars: CarsState;
  loading: LoadingState;
  sequence: SequenceState;
}
