import produce from "immer";
import { handleActions, Action } from "redux-actions";
import { action } from "typesafe-actions";

export enum LoadingTypes {
  setAsync = "@loading/setAsync",
  setLoadingCount = "@loading/setLoadingCount",
}

export interface LoadingState {
  asyncMap: { [key: string]: number };
  loadingCount: number;
}

export const LoadingActions = {
  setAsync: (key: string, value: boolean) =>
    action(LoadingTypes.setAsync, { key, value }),
  addLoadingCount: () => action(LoadingTypes.setLoadingCount),
};

const initialState: LoadingState = {
  asyncMap: {},
  loadingCount: 0,
};

export default handleActions<LoadingState, any>(
  {
    [LoadingTypes.setAsync]: (
      state,
      action: Action<{ key: string; value: boolean }>
    ) => {
      return produce(state, (draft) => {
        const { key, value } = action.payload;
        if (draft.asyncMap[key] === undefined) {
          draft.asyncMap[key] = 0;
        }

        if (value) {
          draft.asyncMap[key]++;
        } else if (draft.asyncMap[key] > 0) {
          draft.asyncMap[key]--;
        }
      });
    },
    [LoadingTypes.setLoadingCount]: (state) => {
      return produce(state, (draft) => {
        draft.loadingCount = state.loadingCount + 1;
      });
    },
  },
  initialState
);
