import { all, put, take } from "redux-saga/effects";
import { createAsyncSaga } from "../cores/createAsyncSaga";
import { CarsActions, CarsTypes } from "../store/cars";
import { LoadingActions } from "../store/loading";

export default function* () {
  yield all([
    ...createAsyncSaga(CarsActions),
    watchGetSimilarCars(),
    watchGetReviews(),
  ]);
}

function* watchGetSimilarCars() {
  while (true) {
    yield take(CarsTypes.getSimilarCars + "/SUCCESS");
    yield put(LoadingActions.addLoadingCount());
  }
}

function* watchGetReviews() {
  while (true) {
    yield take(CarsTypes.getReviews + "/SUCCESS");
    yield put(LoadingActions.addLoadingCount());
  }
}
