import { Dispatch, SetStateAction, useState, useLayoutEffect } from "react";

import { useLocation } from "react-router-dom";

type DefaultParams = { defaultTemplateID: string; defaultURLID: string };
type UseAppLinkReturnType = { link: string; query: string; storeParams: (defaultIDs: DefaultParams) => void };

const MKT_APPSFLYER_TEMPLATE_ID_KEY = "mkt_af_tpl_id";
const MKT_APPSFLYER_URL_ID_KEY = "mkt_af_id";

const handlers: Dispatch<SetStateAction<URLSearchParams>>[] = [];

const useApplink = (projectKey: string): UseAppLinkReturnType => {
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const [params, setParams] = useState(new URLSearchParams(sessionStorage.getItem(projectKey) ?? ""));

  const storeParams = (defaultParams: DefaultParams) => {
    const sessionStoredParams = new URLSearchParams(sessionStorage.getItem(projectKey) ?? "");

    sessionStoredParams.set(
      MKT_APPSFLYER_TEMPLATE_ID_KEY,
      sessionStoredParams.get(MKT_APPSFLYER_TEMPLATE_ID_KEY) ?? defaultParams.defaultTemplateID
    );
    sessionStoredParams.set(
      MKT_APPSFLYER_URL_ID_KEY,
      sessionStoredParams.get(MKT_APPSFLYER_URL_ID_KEY) ?? defaultParams.defaultURLID
    );

    searchParams.forEach((value, key) => sessionStoredParams.set(key, value));
    sessionStorage.setItem(projectKey, sessionStoredParams.toString());

    handlers.map((setParams) => setParams(sessionStoredParams));
  };

  const templateID = params.get(MKT_APPSFLYER_TEMPLATE_ID_KEY);
  const urlID = params.get(MKT_APPSFLYER_URL_ID_KEY);

  const query = new URLSearchParams(params).toString();
  const link = new URL(`${templateID}/${urlID}`, "https://applink.heydealer.com");
  link.search = query;

  useLayoutEffect(() => {
    handlers.push(setParams);

    return () => {
      handlers.splice(handlers.indexOf(setParams), 1);
    };
  }, []);

  return { link: link.toString(), query, storeParams };
};

export default useApplink;
