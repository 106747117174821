import React from "react";
import styles from "./CarInfoSection.module.scss";
import { get, map } from "lodash";
import { CarInfoSections, Item } from "../../../declaration/cars";
import FieldSet from "../FieldSet/FieldSet";

interface Props {
  carInfoSection: CarInfoSections;
  isOpen: boolean;
}

const CarInfoSection: React.FC<Props> = ({ carInfoSection, isOpen }) => {
  const items = get(carInfoSection, "items");

  const renderItems = (item: Item, index: number) => {
    const type = get(item, "type");

    switch (type) {
      case "labeled_text":
        return <FieldSet item={item} isOpen={isOpen} key={index} />;
      case "margin":
        if (!isOpen) {
          return null;
        }
        return <div className={styles.margin} key={index} />;
    }
  };
  return <>{map(items, (item, index) => renderItems(item, index))}</>;
};

export default CarInfoSection;
