import React, { FC, useState } from "react";
import { get, map } from "lodash";
import styles from "./FAQ.module.scss";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import FaqCollapse from "../FaqCollapse/FaqCollapse";

const Faq: FC = () => {
  const [openCollapse, setOpenCollapse] = useState<number | null>(null);
  const { car } = useTypedSelector(({ cars: { car } }) => {
    return {
      car,
    };
  });

  const faqs = get(car, "faqs");

  if (!faqs) {
    return null;
  }
  return (
    <div className={styles.faq}>
      {map(faqs, (faq, index) => {
        return (
          <FaqCollapse
            key={index}
            title={faq.title}
            content={faq.content}
            collapseNumber={index}
            openCollapse={openCollapse}
            setOpenCollapse={setOpenCollapse}
          />
        );
      })}
    </div>
  );
};

export default Faq;
