import { Button, colors } from "@PRNDcompany/heydealer-ui";
import { FC } from "react";
import useApplink from "src/hooks/useApplink";
import { MKT_PROJECT_KEY } from "../../constants";

const CTA: FC = () => {
  const { link } = useApplink(MKT_PROJECT_KEY);

  return (
    <div
      style={{
        display: "grid",
        gap: "1rem",
        padding: "1rem",
        width: "100%",
        boxSizing: "border-box",
        background: colors.base_white,
        position: "sticky",
        bottom: 0,
        isolation: "isolate",
        zIndex: 1,
        borderTopLeftRadius: "1rem",
        borderTopRightRadius: "1rem",
      }}
    >
      <Button
        variant="primary"
        fullWidth
        onClick={() => {
          window.fbq("trackCustom", "[5초안에 시세조회] 버튼 클릭");
          window.open(link, "_blank");
        }}
      >
        5초만에 시세확인
      </Button>
    </div>
  );
};

export default CTA;
