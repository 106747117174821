import React, { useEffect } from "react";
import styles from "./Reviews.module.scss";
import Typography from "../Typograph/Typography";
import { useDispatch } from "react-redux";
import { CarsActions } from "../../store/cars";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import { get, map } from "lodash";
import Review from "./Review/Review";

interface Props {
  hashId: string;
  moreReviewDescriptionHidden?: boolean;
}

const Reviews: React.FC<Props> = ({ hashId, moreReviewDescriptionHidden = false }) => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(CarsActions.getReviews(hashId));
  }, []);
  const { reviewSection } = useTypedSelector(({ cars: { reviewSection } }) => {
    return {
      reviewSection,
    };
  });

  const reviews = get(reviewSection, "reviews");
  return (
    <div className={styles.reviews}>
      <Typography variant="h1" className={styles.title}>
        판매후기
      </Typography>
      <div className={styles.reviewList}>
        {map(reviews, (review, index) => {
          const inputtedAt = get(review, "inputted_at");
          return <Review review={review} key={`${inputtedAt}-${index}`} />;
        })}
      </div>
      {!moreReviewDescriptionHidden && (
        <div className={styles.description}>더 많은 후기는 헤이딜러 앱에서 확인하세요</div>
      )}
    </div>
  );
};

export default Reviews;
