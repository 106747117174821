import React, { FC, useEffect, useState } from "react";
import styles from "./ImageLoader.module.scss";
import classNames from "classnames";

interface Props {
  noLoading?: boolean;
  className?: string;
  image: string;
  zIndex?: number;
  imageClassName?: string;
}

const ImageLoader: FC<Props> = ({
  className,
  image,
  noLoading,
  zIndex,
  imageClassName,
}) => {
  const [isLoading, setLoading] = useState(false);
  const [isLoaded, setLoaded] = useState(false);
  const [isEnded, setEnded] = useState(false);

  useEffect(() => {
    setLoading(true);

    const img = new Image();
    img.src = image;
    img.addEventListener("load", () => {
      setLoaded(true);
      setLoading(false);
    });
  }, [image]);

  useEffect(() => {
    if (isLoaded) {
      const timeout = setTimeout(() => {
        setEnded(true);
      }, 600);

      return () => {
        clearTimeout(timeout);
      };
    }
  }, [isLoaded]);

  return (
    <div className={classNames(styles.imageLoader, className)}>
      {noLoading
        ? null
        : !isEnded && (
            <div
              className={classNames(
                styles.loading,
                !isLoading && styles.disabled
              )}
            />
          )}
      <div
        className={classNames(styles.image, imageClassName)}
        style={{
          background: `url(${image}) no-repeat center`,
          backgroundSize: "cover",
          opacity: isLoading ? 0 : 1,
          zIndex,
        }}
      />
    </div>
  );
};

export default ImageLoader;
