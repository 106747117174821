import React, { useEffect } from "react";
import { useHistory } from "react-router";

const Home: React.FC<{}> = () => {
  useEffect(() => {
    window.location.href = "https://heydealer.co.kr";
  }, []);
  return <div></div>;
};

export default Home;
