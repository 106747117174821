import React from "react";
import styles from "./BusinessCarNumber.module.scss";
import classNames from "classnames";
import { ICarNumber } from "../GeneralCarNumber/GeneralCarNumber";
import CarNumberPosition from "../CarNumberPosition/CarNumberPosition";

interface Props extends ICarNumber {}
const BusinessCarNumber: React.FC<Props> = ({
  region1,
  region2,
  head,
  middle,
  tail,
  isInspector,
}) => {
  return (
    <CarNumberPosition className={styles.business} isInspector={isInspector}>
      <span className={classNames(styles.dot, styles.leftDot)} />
      <span className={classNames(styles.hangle, styles.regionWrapper)}>
        <span className={styles.region}>{region1}</span>
        <span className={styles.region}>{region2}</span>
      </span>
      <span className={styles.number}>{head}</span>
      <span className={styles.hangle}>{middle}</span>
      <span className={classNames(styles.number, styles.tail)}>{tail}</span>
      <span className={classNames(styles.dot, styles.rightDot)} />
      <div className={styles.border} />
    </CarNumberPosition>
  );
};

export default BusinessCarNumber;
