import React from "react";
import styles from "./GeneralCarNumber.module.scss";
import classNames from "classnames";
import CarNumberPosition from "../CarNumberPosition/CarNumberPosition";

export interface ICarNumber {
  region1?: string;
  region2?: string;
  head?: string;
  middle?: string;
  tail?: string;
  isInspector: boolean;
}

interface Props extends ICarNumber {
  isElectric?: boolean;
}
const GeneralCarNumber: React.FC<Props> = ({
  region1,
  region2,
  head,
  middle,
  tail,
  isElectric = false,
  isInspector,
}) => {
  return (
    <div>
      <CarNumberPosition
        className={classNames({
          [styles.electric]: isElectric,
        })}
        isInspector={isInspector}
      >
        <span className={classNames(styles.dot, styles.leftDot)} />
        <span className={styles.hangle}>
          {region1}
          {region2}
        </span>
        <span className={styles.number}>{head}</span>
        <span className={styles.hangle}>{middle}</span>
        <span className={classNames(styles.number, styles.tail)}>{tail}</span>
        <span className={classNames(styles.dot, styles.rightDot)} />
        <div className={styles.border} />
      </CarNumberPosition>
    </div>
  );
};

export default GeneralCarNumber;
