import React from "react";
import styles from "./OldCarNumber.module.scss";
import { ICarNumber } from "../GeneralCarNumber/GeneralCarNumber";
import classNames from "classnames";
import CarNumberPosition from "../CarNumberPosition/CarNumberPosition";

interface Props extends ICarNumber {}

const OldCarNumber: React.FC<Props> = ({
  region1,
  region2,
  head,
  middle,
  tail,
  isInspector,
}) => {
  return (
    <CarNumberPosition className={styles.old} isInspector={isInspector}>
      <div className={styles.row}>
        <span className={classNames(styles.dot, styles.leftDot)} />
        <div className={classNames(styles.regionWrapper)}>
          <div className={styles.region}>{region1}</div>
          <div className={styles.region}>{region2}</div>
        </div>
        <div className={classNames(styles.number, styles.head)}>{head}</div>
        <span className={classNames(styles.dot, styles.rightDot)} />
      </div>
      <div className={styles.row}>
        <span className={styles.hangle}>{middle}</span>
        <span className={styles.number}>&nbsp;{tail}</span>
      </div>

      <div className={styles.border} />
    </CarNumberPosition>
  );
};

export default OldCarNumber;
