import produce from 'immer';
import { Reducer, handleActions, Action } from 'redux-actions';
import { AnyAction } from 'redux';
import { action, PayloadAction, PayloadMetaAction } from 'typesafe-actions';
import createAsyncSagaAction from '../cores/createAsyncSagaAction';

export enum SequenceTypes {
  createSequence = '@sequence/sequence',
}

export type SequenceFunction = (...args: any[]) => any;
export type CreateSequenceInput = Array<Action<any> | PayloadAction<any, any> | PayloadMetaAction<any, any, any> | SequenceFunction | any>;

export interface SequenceState {}

export const SequenceActions = {
  createSequence: (sagaSequence: CreateSequenceInput) => action(SequenceTypes.createSequence, sagaSequence),
};

const initialState: SequenceState = {};

export default handleActions<SequenceState, any>({}, initialState);
