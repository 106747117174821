import React, { FC } from "react";
import styles from "./Banner.module.scss";
import Typography from "../Typograph/Typography";
import LightInk from "../LightInk/LightInk";

const Banner: FC = () => {
  const onClickButton = () => {
    window.open("https://heydealer.onelink.me/tzcX?pid=NaverSEO");
  };
  return (
    <div className={styles.banner} onClick={onClickButton}>
      <div className={styles.contentWrapper}>
        <img src="/UI_rsc_thumbnail_heydealer.png" alt="헤이딜러" className={styles.thumbnail} />
        <div className={styles.content}>
          <div>[번호판] 시세조회, No.1 내차팔기 앱</div>
          <Typography variant="h3">헤이딜러</Typography>
        </div>
      </div>
      <button className={styles.installBtn}>설치</button>
      <LightInk />
    </div>
  );
};

export default Banner;
