import React, { useEffect, useRef } from "react";
import styles from "./LightInk.module.scss";

const LightInk: React.FC<{}> = () => {
  const dummyRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (dummyRef && dummyRef.current) {
      const containerEl = dummyRef.current.parentElement;
      if (containerEl) {
        containerEl.classList.add(styles.rippleParent);
        containerEl.addEventListener("click", (e) => {
          const spanEl = document.createElement("span");
          const x = e.pageX - containerEl.getBoundingClientRect().left;
          const y =
            e.pageY -
            (containerEl.getBoundingClientRect().top +
              (window ? window.scrollY : 0));

          spanEl.classList.add(styles.rippleSpan);
          spanEl.style.top = `${y}px`;
          spanEl.style.left = `${x}px`;
          containerEl.appendChild(spanEl);
        });
      }
    }
  }, []);

  return <div ref={dummyRef} className={styles.dummy} />;
};

export default LightInk;
