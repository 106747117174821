import type { FC } from "react";
import { Route, Switch, useRouteMatch } from "react-router-dom";
import SamplePage from "./SamplePage";

const Router: FC = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route path={`${path}/sample`} component={SamplePage} />
    </Switch>
  );
};

export default Router;
