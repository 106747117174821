import styles from "./SamplePage.module.scss";
import React, { FC, useEffect, useRef, useState } from "react";
import { Engine } from "../../../components/Engine";
import { sleep } from "../../../cores/sleep";
import ServiceInfo from "../../../components/ServiceInfo/ServiceInfo";
import CarInfo from "../../../components/CarInfo/CarInfo";
import { useDispatch } from "react-redux";
import { CarsActions } from "../../../store/cars";
import { SequenceActions } from "../../../store/sequence";
import SimilarCars from "../../../components/SimilarCars/SimilarCars";
import Reviews from "../../../components/Reviews/Reviews";
import Faq from "../../../components/FAQ/FAQ";
import CarNumber from "../../../components/CarNumber/CarNumber";
import { LoadingActions } from "../../../store/loading";
import { CarInfoStatus } from "src/types";
import CTA from "./components/CTA";
import { ResizeObserver } from "@juggle/resize-observer";
import { ResizeObserverCallback } from "@juggle/resize-observer/lib/ResizeObserverCallback";

const INITIAL_CAR_INFO_HEIGHT = 204;
const hashId = "sample";

const SamplePage: FC = () => {
  const [carInfoStatus, setCarInfoStatus] = useState<CarInfoStatus>("closed");
  const [isShow, setShow] = useState(false);
  const [openCarInfoHeight, setOpenCarInfoHeight] = useState(INITIAL_CAR_INFO_HEIGHT);

  const chartRef = useRef<HTMLDivElement>(null);
  const carInfoHeight = carInfoStatus === "opened" ? openCarInfoHeight : INITIAL_CAR_INFO_HEIGHT;

  const getChartMarginTop = () => {
    if (carInfoStatus === "closed") return INITIAL_CAR_INFO_HEIGHT;
    else if (carInfoStatus === "opened") return openCarInfoHeight;
    else return 0;
  };

  const openCarInfoRef = useRef<HTMLDivElement>(null);
  const carInfoRef = useRef<HTMLDivElement>(null);
  const dispatch = useDispatch();

  useEffect(() => {
    const onTransitionend = () => {
      setShow((prev) => !prev);
    };
    if (chartRef.current) {
      chartRef.current.addEventListener("transitionend", onTransitionend);
    }
    return () => {
      if (chartRef.current) {
        chartRef.current.removeEventListener("transitionend", onTransitionend);
      }
    };
  }, [carInfoStatus]);

  const onEndAnimation = () => {
    dispatch(LoadingActions.addLoadingCount());
  };

  useEffect(() => {
    const engine = new Engine(onEndAnimation);

    window.reload = () => {
      engine.reload();
    };

    function* init() {
      while (true) {
        if (hashId) {
          yield engine.init(hashId);
          break;
        }

        yield sleep(100);
      }
    }

    async function main() {
      const initGenerator = init();
      while (true) {
        const { value, done } = initGenerator.next();

        if (done) {
          break;
        }

        await value;
      }
    }

    main();

    if (hashId) {
      dispatch(
        SequenceActions.createSequence([
          CarsActions.getCarInfo(hashId),
          LoadingActions.addLoadingCount(),
          () => {
            /* 차량 정보 세션 전체 보기 height 얻기 */
            if (openCarInfoRef.current) {
              setOpenCarInfoHeight(openCarInfoRef.current.getBoundingClientRect().height);
            }
          },
        ])
      );
    }
  }, [hashId]);

  const handleResize: ResizeObserverCallback = (entries) => {
    window.parent.postMessage({ type: "resize", height: entries[0].contentRect.height }, "*");
  };

  useEffect(() => {
    const resizeObserver = new ResizeObserver(handleResize);
    resizeObserver.observe(document.documentElement);

    return () => resizeObserver.disconnect();
  }, []);

  return (
    <>
      <div className={styles.container}>
        <CarNumber />
        <CarInfo
          setCarInfoStatus={setCarInfoStatus}
          carInfoHeight="auto"
          carInfoStatus="opened"
          ref={openCarInfoRef}
          isDummy={true}
          isShow={isShow}
        />
        <CarInfo
          setCarInfoStatus={setCarInfoStatus}
          carInfoHeight={carInfoHeight}
          carInfoStatus={carInfoStatus}
          ref={carInfoRef}
          isShow={isShow}
        />
        <div ref={chartRef} className={styles.chart} id="chart" style={{ marginTop: getChartMarginTop() }} />
        <SimilarCars hashId={hashId} hideShowMoreButton />
        <ServiceInfo />
        <Reviews hashId={hashId} moreReviewDescriptionHidden />
        <div className={styles.faqBorder} />
        <Faq />
        <CTA />
      </div>
    </>
  );
};

export default SamplePage;
