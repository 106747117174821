import React, { Dispatch, forwardRef, HTMLAttributes, SetStateAction } from "react";
import styles from "./CarInfo.module.scss";
import classNames from "classnames";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import { get, map, size } from "lodash";
import CarInfoSection from "./CarInfoSection/CarInfoSection";
import { CarsTypes } from "../../store/cars";
import CarInfoPlaceHolder from "../CarInfoPlaceHolder/CarInfoPlaceHolder";
import ShowMore from "./ShowMore/ShowMore";
import { CarInfoStatus } from "src/types";

interface Props {
  carInfoHeight: number | string;
  setCarInfoStatus: Dispatch<SetStateAction<CarInfoStatus>>;
  carInfoStatus: CarInfoStatus;
  className?: string;
  isDummy?: boolean;
  isShow: boolean;
}

const CarInfo: React.ForwardRefRenderFunction<HTMLDivElement, Props & HTMLAttributes<HTMLDivElement>> = (
  { carInfoHeight, setCarInfoStatus, carInfoStatus, className, isDummy = false, isShow },
  ref
) => {
  const { car, isLoading } = useTypedSelector(({ cars: { car }, loading: { asyncMap } }) => {
    return {
      isLoading: get(asyncMap, CarsTypes.getCarInfo) > 0,
      car,
    };
  });
  const isOpen = carInfoStatus === "opened";
  const carInfoSections = get(car, "car_info_sections");
  const carInfoSectionsLength = size(carInfoSections);
  const onClickShowMore = () => {
    if (isOpen) {
      setCarInfoStatus("closed");
      window.scrollTo(0, 0);
    } else {
      setCarInfoStatus("opened");
    }
  };
  return (
    <div
      className={classNames(styles.carInfo, { [styles.open]: isOpen, [styles.dummy]: isDummy }, className)}
      style={{ height: carInfoHeight }}
      ref={ref}
    >
      {isLoading && !isDummy ? (
        <CarInfoPlaceHolder />
      ) : (
        <div>
          {map(carInfoSections, (carInfoSection, index) => (
            <>
              <CarInfoSection key={index} carInfoSection={carInfoSection} isOpen={isOpen} />
              {isOpen && index !== carInfoSectionsLength - 1 && <div className={styles.border} />}
            </>
          ))}
        </div>
      )}
      <ShowMore isOpen={isShow} onClick={onClickShowMore} />
    </div>
  );
};

export default forwardRef(CarInfo);
