import React, { FC } from "react";
import styles from "./CarNumber.module.scss";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import { get, includes } from "lodash";
import { CarsTypes } from "../../store/cars";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import GeneralCarNumber from "./GeneralCarNumber/GeneralCarNumber";
import BusinessCarNumber from "./BusinessCarNumber/BusinessCarNumber";
import OldCarNumber from "./OldCarNumber/OldCarNumber";

const REGION_REGEX_TEXT = "^([가-힣][가-힣])?";
const OLD_REGION_REGEX_TEXT = "^([가-힣][가-힣])";
const HEAD_REGION_TEXT = "[0-9]{2,3}";
const OLD_HEAD_REGION_TEXT = "[0-9]";
const MIDDLE_REGION_TEXT = "[가-힣]";
const SPECIAL_UNIT = "[^A-Za-z0-9ㄱ-ㅎㅏ-ㅣ가-힣]";
const KOREA_UNIT = `[ㄱ-ㅎ가-힣]${SPECIAL_UNIT}?`;

interface Props {
  isInspector?: boolean;
}
const CarNumber: FC<Props> = ({ isInspector = false }) => {
  const { inspectorCarInfo } = useTypedSelector(
    ({ cars: { inspectorCarInfo } }) => {
      return {
        inspectorCarInfo,
      };
    }
  );

  const { car, isLoading } = useTypedSelector(
    ({ cars: { car }, loading: { asyncMap } }) => {
      return {
        isLoading: get(asyncMap, CarsTypes.getCarInfo) > 0,
        car,
      };
    }
  );

  const carInfo = isInspector ? inspectorCarInfo : car;
  const carNumber = get(carInfo, "car_number");
  const region1CaptureRegExp = new RegExp(`(^${KOREA_UNIT})`);
  const region2CaptureRegExp = new RegExp(`^[가-힣](${KOREA_UNIT})`);
  const headCaptureRegExp = new RegExp(`${REGION_REGEX_TEXT}([0-9]{1,3})`);
  const middleCaptureRegExp = new RegExp(
    `((${REGION_REGEX_TEXT}${HEAD_REGION_TEXT})|(${OLD_REGION_REGEX_TEXT}${OLD_HEAD_REGION_TEXT}))(${KOREA_UNIT})`
  );
  const tailCaptureRegExp = new RegExp(
    `((${REGION_REGEX_TEXT}${HEAD_REGION_TEXT})|(${OLD_REGION_REGEX_TEXT}${OLD_HEAD_REGION_TEXT}))${MIDDLE_REGION_TEXT}([0-9]{1,4})`
  );

  const region1 = get(carNumber?.match(region1CaptureRegExp), 0);
  const region2 = get(carNumber?.match(region2CaptureRegExp), 1);
  const head = get(carNumber?.match(headCaptureRegExp), 2);
  const middle = get(carNumber?.match(middleCaptureRegExp), 6);
  const tail = get(carNumber?.match(tailCaptureRegExp), 6);

  const fuel = get(carInfo, "fuel");
  const isElectric = fuel === "electric";

  const renderCarNumber = () => {
    if (isElectric) {
      return (
        <GeneralCarNumber
          isElectric
          region1={region1}
          region2={region2}
          head={head}
          middle={middle}
          tail={tail}
          isInspector={isInspector}
        />
      );
    } else {
      if (region1 && region2) {
        if (includes("바사아자하허호배", middle)) {
          return (
            <BusinessCarNumber
              region1={region1}
              region2={region2}
              head={head}
              middle={middle}
              tail={tail}
              isInspector={isInspector}
            />
          );
        } else {
          return (
            <OldCarNumber
              region1={region1}
              region2={region2}
              head={head}
              middle={middle}
              tail={tail}
              isInspector={isInspector}
            />
          );
        }
      } else {
        return (
          <GeneralCarNumber
            region1={region1}
            region2={region2}
            head={head}
            middle={middle}
            tail={tail}
            isInspector={isInspector}
          />
        );
      }
    }
  };
  return (
    <>
      {isLoading ? (
        <SkeletonTheme>
          <Skeleton
            width={143}
            height={33}
            className={styles.carNumberPlaceHolder}
          />
        </SkeletonTheme>
      ) : (
        renderCarNumber()
      )}
    </>
  );
};

export default CarNumber;
