import { easeLinear } from 'd3-ease';

export class AnimatedValue {
  from: number;
  to: number;
  elapsedTime: number = 0;
  time: number = 0;
  delay: number;
  timeScale: number;
  easingFunction: (t: number) => number;
  isCompleted: boolean = false;

  constructor(from: number = 0, to: number = 1, duration: number = 1000, delay: number = 0, easingFunction = easeLinear) {
    this.from = from;
    this.to = to;
    this.delay = delay / 1000;
    this.easingFunction = easingFunction;
    this.timeScale = 1000 / duration;
  }

  get value() {
    return this.from + (this.to - this.from) * this.easingFunction.call(null, this.elapsedTime);
  }

  reset() {
    this.elapsedTime = 0;
    this.isCompleted = false;
    this.time = 0;
  }

  update(delta: number) {
    this.time += delta;

    if (this.isCompleted) {
      return;
    }

    if (this.time < this.delay) {
      return;
    }

    this.elapsedTime += delta * this.timeScale;
    if (this.elapsedTime >= 1) {
      this.elapsedTime = 1;
      this.isCompleted = true;
    }
  }
}
