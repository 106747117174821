import React from "react";
import styles from "./CarInfoPlaceHolder.module.scss";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";

interface Props {}

const CarInfoPlaceHolder: React.FC<Props> = () => {
  return (
    <SkeletonTheme>
      <div className={styles.placeholder}>
        <div className={styles.fieldSet}>
          <Skeleton  width={60} className={styles.cell}/>
          <Skeleton  width={180}/>
        </div>
        <div className={styles.fieldSet}>
          <Skeleton  width={60} className={styles.cell}/>
          <Skeleton  width={90}/>
        </div>
        <div className={styles.fieldSet}>
          <Skeleton  width={60} className={styles.cell}/>
          <Skeleton  width={100}/>
        </div>
      </div>
    </SkeletonTheme>
  );
};

export default CarInfoPlaceHolder;
