import React from "react";
import styles from "./Reduction.module.scss";
import Typography from "../../../Typograph/Typography";
import { get, map, take } from "lodash";
import getThumbnailUrl from "../../../../cores/getThumbnailUrl";
import { Trade } from "../../../../declaration/cars";

interface Props {
  trade: Trade;
}
const Reduction: React.FC<Props> = ({ trade }) => {
  const inspectionImages = get(trade, "inspection_images");
  const reductionsForReview = get(trade, "reductions_for_review");
  return (
    <div className={styles.container}>
      <Typography variant="body2">가격조정 이유</Typography>
      <div className={styles.inspectionImages}>
        {take(inspectionImages, 5).map((image, index) => (
          <img
            className={styles.inspectionImage}
            src={`${getThumbnailUrl(100)}/${image.url}`}
            key={image?.id}
            alt={`감가사진${index}`}
          />
        ))}
      </div>
      <div className={styles.reductionWrapper}>
        {map(reductionsForReview, (reduction, index) => (
          <div
            key={`${reduction?.category}${index}`}
            className={styles.reduction}
          >
            <div className={styles.contentWrapper}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                viewBox="0 0 16 16"
                className={styles.bracket}
              >
                <g fill="none" fillRule="evenodd">
                  <g fill="#CCD8E8" fillRule="nonzero">
                    <g>
                      <g>
                        <g>
                          <g>
                            <g>
                              <path
                                d="M11.833 3.5L11.833 10.667 10.833 10.667 10.833 4.5 4.667 4.5 4.667 3.5z"
                                transform="translate(-56 -2812) translate(0 1408) translate(24 1020) translate(16 216) translate(0 160) translate(16 8) rotate(-180 8.25 7.083)"
                              />
                            </g>
                          </g>
                        </g>
                      </g>
                    </g>
                  </g>
                </g>
              </svg>
              <div
                className={styles.content}
                dangerouslySetInnerHTML={{
                  __html: reduction ? reduction.content : "",
                }}
              />
            </div>
            <div className={styles.price}>
              -{reduction ? `${reduction.price}만` : "-"}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Reduction;
