import { chain } from 'lodash';

export interface Asset {
  name: string;
  src: string;
}

export class AssetManager {
  private static _assetMap: { [key: string]: HTMLImageElement } = {};

  public static async loadAll(assets: Array<Asset>) {
    return Promise.all(chain(assets).map((asset) => {
      return new Promise(((resolve, reject) => {
        const image = new Image();
        image.src = asset.src;
        image.addEventListener('load', () => {
          this._assetMap[asset.name] = image;
          resolve(image);
        });

        image.addEventListener('error', () => {
          reject();
        })
      }))
    }).value())
  }

  public static create(name: string, src: string): Asset {
    return { name, src };
  }

  public static get(name: string): HTMLImageElement | null {
    return this._assetMap[name] || null;
  }
}
